import { OneOf, PartialField } from "./Common.model";
import { ProductsModel } from "./Product.model";
import { SubscriptionsModel } from "./Subscription.model";
import { TaxRegime } from "./TaxRegime.enum";

export namespace CrmProviderHubspotModel {
  namespace Tools {
    export type SearchOut<T = any> = { results: T[]; total: number };
  }

  export namespace Owners {
    export enum OwnerIds {
      "alicia.miorcec@izimmo.com" = "1939771811",
      "growth@ownily.fr" = "410588860",
      "caroline.bosser@ownily.fr" = "759714925",
      "cab@izimmo.com" = "1521621968",
    }

    export interface Owner {
      id: OwnerIds | string;
      userId: number;
      firstName: string;
      lastName: string;
      email: string;
      teams?: { id: string; name: string; primary: boolean }[];
      archived: boolean;
      createdAt: string;
      updatedAt: string;
    }

    export type ListIn = { email?: string };
    export type ListOut = Tools.SearchOut<Owner>;
  }

  export namespace Contacts {
    type Concat<T extends string, U extends string> = `${T};${U}`;
    type Combinations<T extends string, U extends string = T> = T extends any
      ? Exclude<U, T> extends never
        ? T
        : T | Concat<T, Combinations<Exclude<U, T>>>
      : never;

    export interface Contact {
      id: string;
      properties: {
        createdate: string;
        hs_all_contact_vids: string;
        hs_all_owner_ids: string;
        hs_count_is_unworked: string;
        hs_count_is_worked: string;
        hs_email_domain: string;
        hs_is_contact: string;
        hs_is_unworked: string;
        hs_lifecyclestage_lead_date: string;
        hs_marketable_status: string;
        hs_marketable_until_renewal: string;
        hs_membership_has_accessed_private_content: string;
        hs_object_id: string;
        hs_object_source: string;
        hs_object_source_id: string;
        hs_object_source_label: string;
        hs_pipeline: string;
        hs_registered_member: string;
        hs_user_ids_of_all_owners: string;
        hubspot_owner_assigneddate: string;

        // ! Updatable data in Hubspot
        firstname: string;
        lastname: string;
        email: string;
        phone: string;
        address: string;
        city: string;
        taxregime: Combinations<
          "SCI IR" | "SCI IS" | "SCI Projet" | "LMNP" | "LMNP Projet" | "LMNP Micro" | "LMNP Réel" | "LMNP Inconnue"
        >; // Activités
        hs_lead_status: "Prospect" | "Client" | "Ancien Client" | "Partenaire" | "Apporteur d'affaire" | "Concurrent"; // Statut du lead `Prospect` par défaut
        date_de_derniere_connexion: string; //Last login
        id_mongo: string;
        // utm_source: string;
        // utm_medium: string;
        // utm_campagne: string;
        // utm_term: string;
        // utm_content: string;
        optinmarket: boolean;
        compte_supprime: boolean;
        hubspot_owner_id: string; // Id du propriétaire Issa BALDE pour test 1469658580, par défaut Alicia

        env: string;
      };
      archived: boolean;
      createdAt: string;
      updatedAt: string;
    }

    export interface Search {
      filterGroups: [
        {
          filters: (
            | {
                propertyName: "id_mongo";
                operator: "EQ";
                value: Contact["properties"]["id_mongo"];
              }
            | {
                propertyName: "email";
                operator: "EQ";
                value: Contact["properties"]["email"];
              }
          )[];
        }
      ];
    }

    export type CreateIn = {
      properties: Pick<
        Contact["properties"],
        | "firstname"
        | "lastname"
        | "email"
        | "phone"
        // | "address" // ! Not exist
        // | "city" // ! Not exist
        | "taxregime"
        | "hs_lead_status"
        | "id_mongo"
        // | "utm_source"
        // | "utm_medium"
        // | "utm_campagne"
        // | "utm_term"
        // | "utm_content"
        | "optinmarket"
        | "hubspot_owner_id"
        | "env"
      >;
    };
    export type CreateOut = Contact;

    export type SearchIn = OneOf<Pick<Contact["properties"], "id_mongo">, Pick<Contact["properties"], "email">>;
    export type SearchOut = Tools.SearchOut<Contact>;

    export type UpdateIn = Pick<Contact, "id"> & {
      properties: Partial<
        Pick<
          Contact["properties"],
          | "id_mongo"
          | "firstname"
          | "lastname"
          | "email"
          | "phone"
          // | "address" // ! Not exist
          // | "city" // ! Not exist
          | "taxregime"
          | "hs_lead_status" // ! For data migration
          | "optinmarket"
          | "date_de_derniere_connexion"
          | "compte_supprime"
          | "env"
        >
      >;
    };
    export type UpdateOut = Contact;
  }

  export namespace Products {
    export interface Product {
      id: string;
      properties: {
        createdate: string;
        hs_lastmodifieddate: string;
        hs_object_id: string;
        hs_object_source: string;
        hs_object_source_id: string;
        hs_object_source_label: string;
        hs_pipeline: string;
        lifecyclestage: string;

        // ! Updatable data in Hubspot
        name: string;
        type: ProductsModel.ProductTypes;
        siret: string;
        mail_du_compte: string;
        code_naf: string;
        statut: "Prospect" | "Client" | "Ancien Client" | "Partenaire" | "Apporteur d'affaire" | "Concurrent";

        statut_de_l_abonnement:
          | "Actif"
          | "En essai"
          | "Résilié"
          | "Inactif"
          | "Supprimé"
          | "Suspend"
          | "Ended"
          | "Annulé"
          | "En cours de création"
          | "";
        type_d_abonnement: Exclude<SubscriptionsModel.PlanType, SubscriptionsModel.PlanType.Test>; //subscription.plan.type
        engagement: SubscriptionsModel.DurationType;
        first_plan_start_at: string;
        plan_start_at?: SubscriptionsModel.Subscription["startAt"]; // subscription.startAt
        trial_start_date: SubscriptionsModel.Subscription["startAt"]; // startAt
        trial_end_date: SubscriptionsModel.Subscription["endAt"]; // endAt
        plan_end_at?: SubscriptionsModel.Subscription["endAt"]; // subscription.endAt
        statut_payement?: SubscriptionsModel.PaymentStatus; // subscription.payment.status
        payement_method?: SubscriptionsModel.PaymentMethodType; // subscription.payment.payementMethod
        suspend_at?: SubscriptionsModel.Subscription["suspendAt"];
        cancel?: SubscriptionsModel.Subscription["cancelAtPeriodEnd"];
        cancel_reason?: SubscriptionsModel.Subscription["cancelReason"];
        canceled_at?: SubscriptionsModel.Subscription["suspendAt"];

        company_id: string;
        id_mongo: string;
        id_stripe: string;
        bank: "Oui" | "Non";
        hubspot_owner_id: string;
        nombre_de_biens: number;
        regime_fiscal: TaxRegime | "Inconnue";
        supprimer_de_l_app: "Oui" | "Non";

        env: string;
      };
      archived: boolean;
      createdAt: string;
      updatedAt: string;
    }

    export interface Search {
      filterGroups: [
        {
          filters: [
            {
              propertyName: "id_mongo";
              operator: "EQ";
              value: Product["properties"]["id_mongo"];
            }
          ];
        }
      ];
    }

    export type CreateIn = {
      properties: PartialField<
        Pick<
          Product["properties"],
          | "name"
          | "type"
          | "siret"
          | "mail_du_compte"
          | "code_naf"
          | "statut"
          | "statut_de_l_abonnement"
          | "type_d_abonnement"
          | "engagement"
          | "trial_start_date"
          | "trial_end_date"
          | "plan_start_at"
          | "plan_end_at"
          | "company_id"
          | "id_mongo"
          | "id_stripe"
          | "bank"
          | "hubspot_owner_id"
          | "nombre_de_biens"
          | "regime_fiscal"
          | "supprimer_de_l_app"
          | "env"
        >,
        "nombre_de_biens" | "regime_fiscal" | "supprimer_de_l_app"
      >;
    };
    export type CreateOut = Product;

    export type SearchIn = Pick<Product["properties"], "id_mongo">;
    export type SearchOut = Tools.SearchOut<Product>;

    export type UpdateIn = Pick<Product, "id"> & {
      properties: Partial<
        Pick<
          Product["properties"],
          | "name"
          | "type"
          | "siret"
          | "mail_du_compte"
          | "code_naf"
          | "statut"
          | "statut_de_l_abonnement"
          | "type_d_abonnement"
          | "engagement"
          | "trial_start_date" // ! For data migration
          | "trial_end_date" // ! For data migration
          | "plan_start_at"
          | "plan_end_at"
          | "statut_payement"
          | "payement_method"
          | "suspend_at"
          | "cancel"
          | "cancel_reason"
          | "canceled_at"
          | "id_stripe"
          | "bank"
          | "nombre_de_biens"
          | "regime_fiscal"
          | "supprimer_de_l_app"
          | "env"
        >
      >;
    };
    export type UpdateOut = Product;
  }

  export namespace Deals {
    export enum DealStages {
      "Call Welcome" = "614034423",
      "Nouveau Prospect (Welcome)" = "614034416",
      "Perdu (Welcome)" = "614034422",
      "Abonné (Welcome)" = "614034421",
    }
    export enum Pipelines {
      "Welcome" = "398432755",
    }

    export interface Deal {
      id: string;
      properties: {
        createdate: string;
        days_to_close: string;
        hs_closed_amount: string;
        hs_closed_amount_in_home_currency: string;
        hs_closed_won_count: string;
        hs_createdate: string;
        hs_days_to_close_raw: string;
        hs_deal_stage_probability_shadow: string;
        hs_is_closed_won: string;
        hs_is_deal_split: string;
        hs_lastmodifieddate: string;
        hs_object_id: string;
        hs_object_source: string;
        hs_object_source_id: string;
        hs_object_source_label: string;
        hs_projected_amount: string;
        hs_projected_amount_in_home_currency: string;
        lien_maxi: string;

        // ! Updatable data in Hubspot
        dealname: string; // Concatener le product.planType et le firstname et lastname du user séparer d'un tiret
        amount: number; // Le prix du plan récupérer via stripe
        dealstage: DealStages | string; // Call Welcome
        pipeline: Pipelines | string; // Welcome
        closedate?: string;
        engagement: SubscriptionsModel.DurationType;
        hubspot_owner_id: string; // Id du propriétaire Issa BALDE pour test 1469658580, par défaut Alicia
        id_de_la_souscription: string; // subscription._id
        userid: string; // user.Id
        // bank: string; // bank
        type_d_abonnement: Exclude<SubscriptionsModel.PlanType, SubscriptionsModel.PlanType.Test>; //subscription.plan.type
        plan_start_at: SubscriptionsModel.Subscription["startAt"]; // subscription.startAt
        trial_start_date: SubscriptionsModel.Subscription["startAt"]; // startAt
        trial_end_date: SubscriptionsModel.Subscription["endAt"]; // endAt
        plan_end_at: SubscriptionsModel.Subscription["endAt"]; // subscription.endAt
        statut_payement: SubscriptionsModel.PaymentStatus; // subscription.payment.status
        payement_method: SubscriptionsModel.PaymentMethodType; // subscription.payment.payementMethod
        status: SubscriptionsModel.SubscriptionStatus; // subscription.status

        env: string;
      };
      archived: boolean;
      createdAt: string;
      updatedAt: string;
    }

    export interface Search {
      filterGroups: [
        {
          filters: (
            | {
                propertyName: "id_de_la_souscription";
                operator: "EQ";
                value: Deal["properties"]["id_de_la_souscription"];
              }
            | {
                propertyName: "userid";
                operator: "EQ";
                value: Deal["properties"]["userid"];
              }
          )[];
        }
      ];
    }

    export type CreateIn = {
      properties: PartialField<
        Pick<
          Deal["properties"],
          | "dealname"
          | "amount"
          | "dealstage"
          | "pipeline"
          | "engagement"
          | "hubspot_owner_id"
          | "id_de_la_souscription"
          | "userid"
          // | "bank"
          | "type_d_abonnement"
          | "plan_start_at"
          | "trial_start_date"
          | "trial_end_date"
          | "plan_end_at"
          | "statut_payement"
          | "payement_method"
          | "status"
          | "env"
        >,
        "amount"
      >;
    };
    export type CreateOut = Deal;

    export type SearchIn = OneOf<Pick<Deal["properties"], "id_de_la_souscription">, Pick<Deal["properties"], "userid">>;
    export type SearchOut = Tools.SearchOut<Deal>;

    export type UpdateIn = Pick<Deal, "id"> & {
      properties: Partial<
        Pick<
          Deal["properties"],
          | "dealname" // ! For data migration
          | "pipeline" // ! For data migration
          | "amount"
          | "dealstage"
          | "closedate"
          | "engagement"
          | "hubspot_owner_id"
          | "userid"
          // | "bank"
          | "type_d_abonnement"
          | "plan_start_at"
          | "trial_start_date" // ! For data migration
          | "trial_end_date" // ! For data migration
          | "plan_end_at"
          | "statut_payement"
          | "payement_method"
          | "status"
          | "env"
        >
      >;
    };
    export type UpdateOut = Deal;
  }

  export namespace Associations {
    export enum Types {
      CONTACT_TO_PRODUCT = "contact_to_company",
      CONTACT_TO_DEAL = "contact_to_deal",
      PRODUCT_TO_DEAL = "company_to_deal",
    }

    export type CreateItemIn<Type extends Types = Types> = {
      from: {
        id: string;
      };
      to: {
        id: string;
      };
      type: Type;
    };
    export type CreateIn<Type extends Types = Types> = {
      inputs: CreateItemIn<Type>[];
    };
    export type CreateOut<Type extends Types = Types> = {
      completedAt: string;
      startedAt: string;
      status: string;
      results: { from: { id: string }; to: { id: string }; type: Type }[];
    };

    export namespace Labels {
      export enum LabelTypes {
        OWNED = 11,
        GRANTED = 13,
      }
      export type CategoryTypes = {
        HUBSPOT_DEFINED: never;
        USER_DEFINED: LabelTypes;
        INTEGRATOR_DEFINED: never;
      };

      export type UpdateItemIn<CategoryType extends keyof CategoryTypes = keyof CategoryTypes> = {
        associationCategory: CategoryType;
        associationTypeId: CategoryTypes[CategoryType];
      };
      export type UpdateIn = {
        fromId: string;
        toId: string;
        inputs: UpdateItemIn[];
      };
      export type UpdateOut = {
        fromObjectTypeId: string;
        fromObjectId: number;
        toObjectTypeId: string;
        toObjectId: number;
        labels: string[];
      };
    }
  }
}
