






































import Header from "@/components/core/header/Header.vue";
import Loader from "@/components/core/Loader.vue";
import AcceptCgu from "@/components/core/modals/AcceptCgu.vue";
import Welcome from "@/components/core/modals/Welcome.vue";
import WelcomeBack from "@/components/core/modals/WelcomeBack.vue";
import Navigation from "@/components/core/navigation/Navigation.vue";
import ProductTour from "@/components/core/product-tour/ProductTour.vue";
import { useInit } from "@/composables";
import {
  accountingPeriodsStore,
  bankAccountsStore,
  productsStore,
  coreStore,
  documentsStore,
  fixedAssetAmortisationsStore,
  fixedAssetsStore,
  assembliesStore,
  partnersStore,
  realEstateAmortisationsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  rentalBuildingsStore,
  subscriptionsStore,
  tasksStore,
  transactionsStore,
  usersStore,
  activitiesStore,
  tenantsStore,
} from "@/store";
import { AccessModel, TaskStatus, UsersLib } from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import io from "socket.io-client";

export default defineComponent({
  name: "Core",
  components: {
    Header,
    Navigation,
    Loader,
    Welcome,
    ProductTour,
    WelcomeBack,
    AcceptCgu,
  },
  setup(_, context) {
    const { initApp, initOnBoardingStep } = useInit();
    const taskGroups = computed(() => {
      return tasksStore.startWellTaskGroup;
    });

    const dialogNew: Ref<boolean> = ref(
      context.root.$route.query["welcome"] === "new"
    );
    const dialogBack = computed(() => {
      if (
        context.root.$route.query["welcome"] === "back" &&
        !taskGroups.value.every(
          (taskGroup) => taskGroup.status === TaskStatus.COMPLETED
        ) &&
        UsersLib.getProductIdsByMyProductAccessType(
          usersStore.loggedInUser,
          AccessModel.AccessTypes.OWNED
        ).includes(productsStore.currentId)
      ) {
        return true;
      } else {
        return false;
      }
    });
    const openDialogBack = computed(() => {
      let temp = false;
      if (context.root.$route.query["welcome"] === "back" && dialogBack.value) {
        temp = true;
      }
      return temp;
    });
    const persistentDialogBlock = computed(
      () =>
        !usersStore.loggedInUser.tos.find(
          (tosItem) =>
            tosItem.version === coreStore.config.user.tosVersion &&
            tosItem.accepted
        )
    );

    onBeforeMount(async () => {
      // reset all stores
      coreStore.reset();
      subscriptionsStore.reset();
      usersStore.reset();
      bankAccountsStore.reset();
      productsStore.reset();
      accountingPeriodsStore.reset();
      realEstateAssetsStore.reset();
      rentalBuildingsStore.reset();
      rentalAgreementsStore.reset();
      tenantsStore.reset();
      partnersStore.reset();
      assembliesStore.reset();
      documentsStore.reset();
      tasksStore.reset();
      transactionsStore.reset();
      fixedAssetsStore.reset();

      // start loading app
      coreStore.startLoadingApp();
      try {
        await coreStore.getConfig();
        await initApp(context);
        await initOnBoardingStep(context, 0, {
          taxRegime: ref(
            accountingPeriodsStore.currentAccountingPeriod?.taxRegime
          ),
        });
        if (coreStore.config.services.webSocket) {
          const socket = io(location.origin + "/socket");
          socket.on("connect", () => {
            //first call to store userId with socketId
            socket.emit("call", "routes.co", {});
            socket.on("updateProduct", () => {
              productsStore.fetchProducts({});
              tasksStore.fetchTaskGroups({});
            });
            socket.on("updateActivity", () => {
              productsStore.fetchProducts({});
              activitiesStore.fetchActivities(productsStore.currentId);
              tasksStore.fetchTaskGroups({});
            });
            socket.on("updateUser", () => {
              usersStore.fetchLoggedInUser();
              tasksStore.fetchTaskGroups({});
            });
            socket.on("updateTask", () => {
              tasksStore.fetchTaskGroups({});
            });
            socket.on("updateSubscription", () => {
              subscriptionsStore.fetchSubscriptions();
            });
            socket.on("updateAccountingPeriod", () => {
              accountingPeriodsStore.fetchAccountingPeriods(
                productsStore.currentId
              );
            });
            socket.on("updateFixedAssetAmortisation", () => {
              fixedAssetAmortisationsStore.fetchFixedAssetAmortisations(
                productsStore.currentId
              );
            });
            socket.on("updateRealEstateAssetAmortisation", () => {
              realEstateAmortisationsStore.fetchRealEstateAmortisations(
                productsStore.currentId
              );
            });
          });
          //refresh website : keep the connection, ...
          socket.on("disconnect", () => {
            socket.connect();
          });
        }
      } catch (err) {
        // redirect error page or modal error
      }
      coreStore.stopLoadingApp();
    });
    watch(
      () => productsStore.currentId,
      () => (context.root.$route.query["welcome"] = "")
    );

    return {
      dialogBack,
      dialogNew,
      openDialogBack,
      persistentDialogBlock,
      openDialog: computed(
        () => context.root.$route.query["welcome"] === "new"
      ),
      loadingApp: computed(() => coreStore.loadingApp),
      accountingPeriodsStore,
    };
  },
});
