import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import {
  ChargePaymentMethodEnum,
  DPE,
  getMoment,
  ProductsModel,
} from "@edmp/api";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  realEstateAssetsStore,
  rentalAgreementsStore,
  subscriptionsStore,
  tenantsStore,
  usersStore,
} from "@/store";
import { ability } from "@/services";

import Accounting from "@/components/core/accounting/Accounting.vue";
import AccountingOperations from "@/components/core/accounting/AccountingOperations.vue";
import AccountingBalance from "@/components/core/accounting/AccountingBalance.vue";
import AccountingDocuments from "@/components/core/accounting/AccountingDocuments.vue";
import AccountingResult from "@/components/core/accounting/AccountingResult.vue";
import AccountingBalanceSheetPage from "@/components/core/accounting/AccountingBalanceSheetPage.vue";
import AccountingCarryForward from "@/components/core/accounting/AccountingCarryForward.vue";
import BankAccountDetails from "@/components/core/activities/bankAccounts/BankAccountDetails.vue";
import Activity from "@/components/core/activities/Activity.vue";
import ActivityCreate from "@/components/core/activities/ActivityCreate.vue";
import Core from "@/components/core/Core.vue";
import Dashboard from "@/components/core/dashboard/Dashboard.vue";
import Documents from "@/components/core/documents/Documents.vue";
import NewDocuments from "@/components/core/documents/NewDocuments.vue";
import SupportingDocuments from "@/components/core/supportingDocuments/SupportingDocuments.vue";
import EventsGeneralAssemblyDetails from "@/components/core/events/generalAssembly/detail/EventsGeneralAssemblyDetails.vue";
import EventsGeneralAssembly from "@/components/core/events/generalAssembly/EventsGeneralAssembly.vue";
import EventsYearEnd from "@/components/core/events/yearEnd/EventsYearEnd.vue";
import RealEstate from "@/components/core/realEstate/RealEstate.vue";
import Subscriptions from "@/components/core/subscriptions/Subscriptions.vue";
import SubscriptionDetails from "@/components/core/subscriptions/detail/SubscriptionDetails.vue";
import RentalBuildings from "@/components/core/tax-return/rental-buildings/RentalBuildings.vue";
import TaxReturn2072 from "@/components/core/tax-return/cerfa-2072/TaxReturn2072.vue";
import TaxDeclaration2065 from "@/components/core/tax-return/cerfa-2065/TaxDeclaration2065.vue";
import TaxDeclaration2033 from "@/components/core/tax-return/cerfa-2033/TaxDeclaration2033.vue";
import TaxDeclaration2031 from "@/components/core/tax-return/cerfa-2031/TaxDeclaration2031.vue";
import PartnersIncome from "@/components/core/tax-return/partners/PartnersIncome.vue";
import Transactions from "@/components/core/transactions/Transactions.vue";
import User from "@/components/core/user/User.vue";
import ForgetPassword from "@/components/sign/signin/ForgetPassword.vue";
import Login from "@/components/sign/signin/Login.vue";
import ResetPassword from "@/components/sign/signin/ResetPassword.vue";
import ValidateEmail from "@/components/sign/signin/ValidateEmail.vue";
import Registration from "@/components/sign/signup/Registration.vue";
import ValidateBudgetInsight from "@/components/sign/signup/steps/ValidateBudgetInsight.vue";
import ValidateEmailProduct from "@/components/sign/signin/ValidateEmailProduct.vue";
import TasksPage from "@/components/core/tasks/TasksPage.vue";
import RentalAgreements from "@/components/core/rentalAgreements/RentalAgreements.vue";
import Amortisations from "@/components/core/amortisations/Amortisations.vue";
import FixedAsset from "@/components/core/fixedAssets/FixedAsset.vue";
import Error404 from "@/components/core/Error404.vue";
import MyBusiness from "@/components/core/myBusiness/MyBusiness.vue";
import PartnersArea from "@/components/core/partnersArea/PartnersArea.vue";
import RealEstateAssets from "@/components/core/activities/RealEstateAssets.vue";
import RealEstateLMNP from "@/components/core/realEstate/RealEstateLMNP.vue";
import FixedAssetsPage from "@/components/core/fixedAssets/FixedAssetsPage.vue";
import ModelsLibrary from "@/components/core/documents/ModelsLibrary.vue";
import RentalAgreementFlow from "@/components/core/rentalAgreements/RentalAgreementFlow.vue";
import Indexation from "@/components/core/rentalAgreements/Indexation.vue";
import Regularization from "@/components/core/rentalAgreements/RegularizationFlow.vue";
import RegularizationHistory from "@/components/core/rentalAgreements/RegularizationHistory.vue";

import { ROUTE_NAMES } from "./routes";
import { PositionResult } from "vue-router/types/router";
import {
  dispatchIndexationEvent,
  IndexationEventCode,
  dispatchRegularizationEvent,
  RegularizationEventCode,
} from "@/events";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";

Vue.use(VueRouter);

// const canIndexRent = (realEstateAssetId: string, rentalAgreementId: string) => {
//   const realEstateAsset =
//     realEstateAssetsStore.getRealEstateAsset(realEstateAssetId);
//   const rentalAgreement =
//     rentalAgreementsStore.getRentalAgreement(rentalAgreementId);

//   return (
//     (subscriptionsStore.isPremium || subscriptionsStore.isOptimum) &&
//     realEstateAsset?.dpe?.dpeGrade !== DPE.F &&
//     realEstateAsset?.dpe?.dpeGrade !== DPE.G &&
//     !!rentalAgreement?.financialConditions.indexation &&
//     rentalAgreement.financialConditions.indexation?.index
//   );
// };

const routes: Array<RouteConfig> = [
  {
    path: "/signin",
    name: ROUTE_NAMES.SignIn,
    component: Login,
    meta: {
      requiresAuth: false,
      resource: "Signin",
    },
  },
  {
    path: "/validate-email",
    name: ROUTE_NAMES.ValidateEmail,
    component: ValidateEmail,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/validate-email-sci",
    name: ROUTE_NAMES.ValidateEmailProduct,
    component: ValidateEmailProduct,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forget-password",
    name: ROUTE_NAMES.ForgetPassword,
    component: ForgetPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: ROUTE_NAMES.ResetPassword,
    component: ResetPassword,
    meta: {
      requiresAuth: false,
    },
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        next();
      } else {
        next({ name: ROUTE_NAMES.SignIn });
      }
    },
  },
  {
    path: "/signup",
    name: ROUTE_NAMES.SignUp,
    redirect: { name: ROUTE_NAMES.RegisterActivity },
    meta: {
      requiresAuth: false,
    },
    component: {
      // Inline declaration of a component that renders our <router-view>
      render: (c) => c("router-view"),
    },
    children: [
      {
        path: "activity",
        name: ROUTE_NAMES.RegisterActivity,
        component: Registration,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "user",
        name: ROUTE_NAMES.RegisterUser,
        component: Registration,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "activity-details",
        name: ROUTE_NAMES.RegisterActivityDetails,
        component: Registration,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "bank",
        name: ROUTE_NAMES.RegisterBank,
        component: Registration,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "budget-insight/validate",
        name: ROUTE_NAMES.ValidateBI,
        component: ValidateBudgetInsight,
      },
    ],
  },

  {
    path: "/",
    component: Core,
    meta: {
      requiresAuth: true,
    },
    props: (route) => Object.assign({}, route.query, route.params),
    children: [
      {
        path: "/dashboard",
        name: ROUTE_NAMES.Dashboard,
        component: Dashboard,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/transactions",
        name: ROUTE_NAMES.Transactions,
        component: Transactions,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: async (to, from, next) => {
          await tenantsStore.fetchTenants({
            productId: productsStore.currentId,
          });
          next();
        },
      },
      {
        path: "/my-business",
        name: ROUTE_NAMES.MyBusiness,
        component: MyBusiness,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime ===
          //   TaxRegime.LMNP_2031
          // ) {
          //   next();
          // } else
          next();
        },
      },
      {
        path: "/real-estate-assets",
        name: ROUTE_NAMES.RealEstateAssets,
        component: RealEstateAssets,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/rental-buildings",
        name: ROUTE_NAMES.RentalBuildings,
        component: RentalBuildings,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IS_2065 &&
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IR_2072
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
      },
      {
        path: "/sci-income",
        name: ROUTE_NAMES.SciIncome,
        component: TaxReturn2072,
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //   TaxRegime.IR_2072
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/partners-income",
        name: ROUTE_NAMES.PartnersIncome,
        component: PartnersIncome,
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //   TaxRegime.IR_2072
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/tax-declaration-2065",
        name: ROUTE_NAMES.TaxDeclaration2065,
        component: TaxDeclaration2065,
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //   TaxRegime.IS_2065
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/tax-declaration-2033",
        name: ROUTE_NAMES.TaxDeclaration2033,
        component: TaxDeclaration2033,
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IS_2065 &&
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.LMNP_2031
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/tax-declaration-2031",
        name: ROUTE_NAMES.TaxDeclaration2031,
        component: TaxDeclaration2031,
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //   TaxRegime.LMNP_2031
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/events-ag",
        name: ROUTE_NAMES.EventsGeneralAssembly,
        component: EventsGeneralAssembly,
        props: (route) =>
          Object.assign(
            {},
            route.query as {
              newEvent?: "ordinary" | "extraordinary";
              pattern?: "yearEnd" | "other";
            },
            route.params
          ),
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IS_2065 &&
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IR_2072
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
      },
      {
        path: "/events-ag/:productId/detail/:id",
        name: ROUTE_NAMES.EventsGeneralAssemblyDetails,
        component: EventsGeneralAssemblyDetails,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          // if (
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IS_2065 &&
          //   accountingPeriodsStore.currentAccountingPeriod?.taxRegime !==
          //     TaxRegime.IR_2072
          // ) {
          //   next({ name: ROUTE_NAMES.Error404 });
          // } else
          next();
        },
      },
      {
        path: "/events-year-end",
        name: ROUTE_NAMES.EventsYearEnd,
        component: EventsYearEnd,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/documents",
        name: ROUTE_NAMES.Documents,
        component: Documents,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/documents-new",
        name: ROUTE_NAMES.NewDocuments,
        component: NewDocuments,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/supportingDocuments",
        name: ROUTE_NAMES.SupportingDocuments,
        component: SupportingDocuments,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting",
        name: ROUTE_NAMES.AccountingDashboard,
        component: Accounting,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting-documents",
        name: ROUTE_NAMES.AccountingDocuments,
        component: AccountingDocuments,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting-result",
        name: ROUTE_NAMES.AccountingResult,
        component: AccountingResult,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/fec",
        name: ROUTE_NAMES.Fec,
        component: AccountingOperations,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting-balance",
        name: ROUTE_NAMES.AccountingBalance,
        component: AccountingBalance,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting-balance-sheet-recovery",
        name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
        component: AccountingBalanceSheetPage,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: async (to, from, next) => {
          // if (
          //   !accountingBalanceSheetsStore.getFirstAccountingBalanceSheetByRecovery
          // ) {
          //   await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
          //   if (
          //     !accountingBalanceSheetsStore.getFirstAccountingBalanceSheetByRecovery
          //   ) {
          //     next({ name: ROUTE_NAMES.Error404 });
          //   } else next();
          // } else
          next();
        },
      },
      {
        path: "/accounting-balance-sheet",
        name: ROUTE_NAMES.AccountingBalanceSheet,
        component: AccountingBalanceSheetPage,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/accounting-carry-forward",
        name: ROUTE_NAMES.AccountingCarryForward,
        component: AccountingCarryForward,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/user",
        name: ROUTE_NAMES.User,
        component: User,
        meta: {
          resource: "User",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/subscriptions/",
        name: ROUTE_NAMES.Subscriptions,
        component: Subscriptions,
        meta: {
          resource: "Subscription",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/subscriptions/:subscriptionId",
        name: ROUTE_NAMES.SubscriptionDetails,
        component: SubscriptionDetails,
        meta: {
          resource: "Subscription",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/subscriptions/:subscriptionId/:stripeReturnStatus",
        name: ROUTE_NAMES.SubscriptionDetailsContinue,
        component: SubscriptionDetails,
        meta: {
          resource: "Subscription",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId",
        name: ROUTE_NAMES.RealEstate,
        component: RealEstate,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          tenantsStore.fetchTenants({ productId: productsStore.currentId });
          next();
        },
      },
      {
        path: "/activities/:productId/realEstateAssetsLmnp/:realEstateAssetId",
        name: ROUTE_NAMES.RealEstateLMNP,
        component: RealEstateLMNP,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/rent/:rentalAgreementId",
        name: ROUTE_NAMES.RentalAgreementFlow,
        component: RentalAgreementFlow,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          tenantsStore.fetchTenants({ productId: productsStore.currentId });
          next();
        },
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/:rentalAgreementId/indexation",
        name: ROUTE_NAMES.Indexation,
        component: Indexation,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          const realEstateAsset = realEstateAssetsStore.getRealEstateAsset(
            to.params.realEstateAssetId
          );
          const rentalAgreement = rentalAgreementsStore.getRentalAgreement(
            to.params.rentalAgreementId
          );
          if (
            (accountingPeriodsStore.isIR && !subscriptionsStore.isPremium) ||
            (accountingPeriodsStore.isIS && !subscriptionsStore.isOptimum)
          ) {
            dispatchIndexationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: IndexationEventCode.REQUIRES_PREMIUM_OPTIMUM,
            });
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message: `Ce service nécessite un abonnement supérieur. Mettez à jour votre abonnement au niveau ${
                accountingPeriodsStore.isIR
                  ? "Premium"
                  : accountingPeriodsStore.isIS
                  ? "Optimum"
                  : "LMNP"
              } pour y accéder.`,
            });
          } else if (
            (realEstateAsset?.dpe?.dpeGrade === DPE.F ||
              realEstateAsset?.dpe?.dpeGrade === DPE.G) &&
            rentalAgreement?.financialConditions.indexation?.type !== "ilc" &&
            rentalAgreement?.financialConditions.indexation?.type !== "ilat" &&
            rentalAgreement?.financialConditions.indexation?.type !== "icc"
          ) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message:
                "Votre classe énergétique ne permet pas d'effectuer une indexation du loyer.",
            });
          } else if (
            !rentalAgreement?.financialConditions.indexation ||
            !rentalAgreement?.financialConditions.indexation?.index
          ) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message:
                "Votre contrat de location ne contient pas de clause d'indexation.",
            });
          } else {
            dispatchIndexationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: IndexationEventCode.ENTER_INDEXATION_INTERFACE,
            });
            next();
          }
        },
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/:rentalAgreementId/regularization",
        name: ROUTE_NAMES.Regularization,
        component: Regularization,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          const rentalAgreement = rentalAgreementsStore.getRentalAgreement(
            to.params.rentalAgreementId
          );

          if (
            (accountingPeriodsStore.isIR && !subscriptionsStore.isPremium) ||
            (accountingPeriodsStore.isIS && !subscriptionsStore.isOptimum)
          ) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message: `Ce service nécessite un abonnement supérieur. Mettez à jour votre abonnement au niveau ${
                accountingPeriodsStore.isIR
                  ? "Premium"
                  : accountingPeriodsStore.isIS
                  ? "Optimum"
                  : "LMNP"
              } pour y accéder.`,
            });
            dispatchRegularizationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: RegularizationEventCode.REQUIRES_PREMIUM_OPTIMUM,
            });
          } else if (
            rentalAgreement &&
            rentalAgreement.financialConditions.chargePaymentMethod !==
              ChargePaymentMethodEnum.ProvisionOnCharge
          ) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message:
                "La régularisation des charges n'est possible qu'avec la méthode de paiement des charges \"Provision sur charges\"",
            });
          } else {
            dispatchRegularizationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: RegularizationEventCode.ENTER_REGULARIZATION_INTERFACE,
            });
            next();
          }
        },
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/:rentalAgreementId/regularization/history",
        name: ROUTE_NAMES.RegularizationHistory,
        component: RegularizationHistory,
        props: (route) => Object.assign({}, route.query, route.params),
        beforeEnter: (to, from, next) => {
          const rentalAgreement = rentalAgreementsStore.getRentalAgreement(
            to.params.rentalAgreementId
          );

          if (
            (accountingPeriodsStore.isIR && !subscriptionsStore.isPremium) ||
            (accountingPeriodsStore.isIS && !subscriptionsStore.isOptimum)
          ) {
            dispatchRegularizationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: RegularizationEventCode.REQUIRES_PREMIUM_OPTIMUM,
            });
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message: `Ce service nécessite un abonnement supérieur. Mettez à jour votre abonnement au niveau ${
                accountingPeriodsStore.isIR
                  ? "Premium"
                  : accountingPeriodsStore.isIS
                  ? "Optimum"
                  : "LMNP"
              } pour y accéder.`,
            });
          } else if (
            rentalAgreement &&
            rentalAgreement.financialConditions.chargePaymentMethod !==
              ChargePaymentMethodEnum.ProvisionOnCharge
          ) {
            coreStore.displayFeedback({
              type: FeedbackTypeEnum.WARNING,
              message:
                "La régularisation des charges n'est possible qu'avec la méthode de paiement des charges \"Provision sur charges\"",
            });
          } else {
            dispatchRegularizationEvent({
              userId: usersStore.loggedInUser.id,
              productId: productsStore.currentId,
              date: getMoment().toISOString(),
              code: RegularizationEventCode.ENTER_REGULARIZATION_HISTORY_INTERFACE,
            });
            next();
          }
        },
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/rentalUnit/:rentalUnitId",
        name: ROUTE_NAMES.RentalUnit,
        component: RealEstate,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/realEstateLoans/:realEstateLoanId",
        name: ROUTE_NAMES.RealEstateLoan,
        component: RealEstate,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/realEstateAssets/:realEstateAssetId/rentalAgreement/:rentalAgreementId",
        name: ROUTE_NAMES.RentalAgreement,
        component: RealEstate,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/bankAccounts/:id",
        name: ROUTE_NAMES.BankAccountDetails,
        component: BankAccountDetails,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/bankAccounts",
        name: ROUTE_NAMES.BankAccountDetailsActivity,
        component: BankAccountDetails,
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/bankAccounts",
        name: ROUTE_NAMES.BankAccounts,
        component: BankAccountDetails,
        props: (route) => Object.assign({}, route.query, route.params),
        meta: {
          resource: "BankAccount",
        },
      },
      {
        path: "/activities",
        name: ROUTE_NAMES.Activities,
        component: Activity,
        meta: {
          resource: "Product",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/create",
        name: ROUTE_NAMES.ActivityCreate,
        component: ActivityCreate,
        meta: {
          resource: "Product",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/subscription/create",
        name: ROUTE_NAMES.ActivitySubscriptionCreate,
        component: ActivityCreate,
        meta: {
          resource: "Product",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/continue",
        name: ROUTE_NAMES.ActivityContinue,
        component: ActivityCreate,
        meta: {
          resource: "Product",
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/:stripeReturnStatus",
        name: ROUTE_NAMES.ActivityCreateCancel,
        component: ActivityCreate,
        props: (route) => Object.assign({}, route.query, route.params),

        meta: {
          resource: "Product",
        },
      },
      {
        path: "/activities/:productId",
        name: ROUTE_NAMES.ActivityDetail,
        component: Activity,
        props: (route) => Object.assign({}, route.query, route.params),
        meta: {
          resource: "Product",
        },
      },
      {
        path: "/tasks",
        name: ROUTE_NAMES.Tasks,
        component: TasksPage,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/rental-agreements",
        name: ROUTE_NAMES.RentalAgreements,
        component: RentalAgreements,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/amortisations",
        name: ROUTE_NAMES.Amortisations,
        component: Amortisations,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/fixedAssets",
        name: ROUTE_NAMES.FixedAssets,
        component: FixedAssetsPage,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/activities/:productId/fixedAsset/:fixedAssetId",
        name: ROUTE_NAMES.FixedAsset,
        component: FixedAsset,
        meta: {
          requiresAuth: true,
        },
        props: (route) => Object.assign({}, route.query, route.params),
      },
      {
        path: "/error-404",
        name: ROUTE_NAMES.Error404,
        component: Error404,
      },
      {
        path: "/partners-area",
        name: ROUTE_NAMES.PartnersArea,
        component: PartnersArea,
      },
      {
        path: "/models-library",
        name: ROUTE_NAMES.ModelsLibrary,
        component: ModelsLibrary,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to): PositionResult {
    if (to.hash) {
      if (to.hash === "#top") {
        return {
          x: 0,
          y: 0,
          behavior: "smooth",
        };
      } else if (to.hash === "#bottom") {
        return {
          x: 0,
          y:
            document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: "smooth",
        };
      } else {
        return {
          selector: to.hash,
          behavior: "smooth",
          offset: { x: 0, y: 70 },
        };
      }
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const excludedRoutesToFetchUser = [
    ROUTE_NAMES.SignIn,
    ROUTE_NAMES.RegisterActivity,
    ROUTE_NAMES.RegisterUser,
    ROUTE_NAMES.ForgetPassword,
    ROUTE_NAMES.ValidateEmail,
    ROUTE_NAMES.ResetPassword,
  ];
  if (
    !usersStore.isLogged &&
    ((to.name && !excludedRoutesToFetchUser.includes(to.name)) ||
      to.path === "/")
  ) {
    await usersStore.fetchLoggedInUser();
  }

  if (to.path === "/") {
    if (usersStore.isLogged) {
      let product = productsStore.currentProduct;
      if (!product) {
        const products = await productsStore.fetchProducts({});
        product = products[0] as ProductsModel.Product | undefined;
      }
      if (
        product !== undefined &&
        product.status === ProductsModel.ProductStatus.pending
      ) {
        next({ name: ROUTE_NAMES.ActivityCreate });
      } else {
        next({ name: ROUTE_NAMES.Dashboard, query: { welcome: "back" } });
      }
    } else {
      next({ name: ROUTE_NAMES.SignIn });
    }
  } else {
    const canNavigate = to.matched.some((route) =>
      ability.can(route.meta.action || "visit", route.meta.resource)
    );

    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);

    // console.log("canNavigate", canNavigate);
    // console.log("ability", ability);
    // Check navigation when user is authenticated only
    if (requiresAuth && !canNavigate) {
      // User as not subscribe or other redirection in future.
      console.log("cannot navigate on next:", to.path);
      if (
        (productsStore.currentProduct as ProductsModel.Product).status ===
        ProductsModel.ProductStatus.pending
      ) {
        next({ name: ROUTE_NAMES.ActivityCreate });
      } else {
        next({ name: ROUTE_NAMES.Subscriptions });
      }
    } else {
      // Redirecting /signup/user to /signup/activity when coming from outside the application following the onboarding redesign
      if (to.name === ROUTE_NAMES.RegisterUser && !from.name) {
        next({ name: ROUTE_NAMES.RegisterActivity });
      } else {
        next();
      }
    }
  }
});

export default router;
