










































































import {
  defineComponent,
  watch,
  computed,
  ref,
  onBeforeMount,
  ComputedRef,
  Ref,
} from "@vue/composition-api";
import router from "@/router";
import { documentsStore, productsStore, realEstateAssetsStore } from "@/store";
import { RealEstateAsset, Document } from "@edmp/api";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "RealEstatesLMNP",
  props: { productId: { type: String } },

  setup(props) {
    const isLoading = ref(false);
    const realEstateAssetList: ComputedRef<
      (Partial<RealEstateAsset> & Pick<RealEstateAsset, "id">)[]
    > = computed(() =>
      realEstateAssetsStore.realEstateAssets.length !== 0
        ? realEstateAssetsStore.realEstateAssets
        : [{ id: "new" }]
    );
    const documents: Ref<Document[]> = computed(() => documentsStore.documents);

    const isNew = computed(() => realEstateAssetList.value[0].id === "new");

    const clickRealEstate = (itemId: string) => {
      const productId = productsStore.currentId;
      router.push({
        name: ROUTE_NAMES.RealEstateLMNP,
        params: { productId, realEstateAssetId: itemId },
      });
    };

    // Gte Href of document
    const getImage = (documentId: string) => {
      if (documentId) {
        const docId = documentId;
        const document: Document[] = documents.value.filter(
          ({ id }) => id === docId
        );
        return document[0].href;
      }
    };

    // Init
    async function init() {
      isLoading.value = true;
      if (props.productId) {
        await productsStore.switchProduct({ id: props.productId });
      }
      await realEstateAssetsStore.fetchRealEstateAssets(
        productsStore.currentId
      );
      await documentsStore.fetchDocuments();
      isLoading.value = false;
    }

    watch(
      () => productsStore.currentProduct,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      isLoading,
      realEstateAssetList,
      isNew,
      clickRealEstate,
      documents,
      getImage,
    };
  },
});
