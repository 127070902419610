


























































































































































import {
  computed,
  defineComponent,
  ref,
  watch,
  PropType,
} from "@vue/composition-api";
import {
  accountingPeriodsStore,
  productsStore,
  realEstateAssetsStore,
} from "@/store";
import { getMoment } from "@edmp/api";
import EditableTable from "@/components/atom/table/EditableTable.vue";
import Decimal from "decimal.js-light";
import { ROUTE_NAMES } from "@/router/routes";
import DatePicker from "@/components/atom/DatePicker.vue";
export default defineComponent({
  name: "AddDocumentsModalNew",
  components: { EditableTable, DatePicker },
  props: {
    files: { required: true, type: Array as PropType<File[]> },
  },
  setup(props, context) {
    //#region  Documents
    const headersDocuments = [
      { text: "N° du document", value: "number" },
      { text: "Année d'exercice", value: "accountingPeriod" },
      {
        text: "Rubrique",
        value: "tags",
      },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Document", value: "document" },
    ];

    const tags = [
      { text: "Aucune", values: [] },
      {
        text: accountingPeriodsStore.isLMNP
          ? "Mon activité LMNP"
          : "Ma SCI et ses associés",
        values: ["product"],
      },
      { text: "Mes biens", values: ["real-estate-asset"] },
      { text: "Mes loyers", values: ["receipt", "rental-agreement"] },
      { text: "Ma comptabilité", values: ["accounting"] },
      { text: "Mes déclarations fiscales", values: ["tax"] },
      { text: "Mes événements", values: ["event"] },
    ];

    const realEstateAssets = ref([
      { name: "Aucun", id: null },
      ...realEstateAssetsStore.realEstateAssets.map((realEstateAsset) => ({
        name: realEstateAsset.name,
        id: realEstateAsset.id,
      })),
    ]);

    const accountingPeriods = ref([
      { period: "Aucune", id: null },
      ...accountingPeriodsStore.accountingPeriods.map((accountingPeriod) => ({
        period: `${getMoment(accountingPeriod.startAt).format(
          "L"
        )} - ${getMoment(accountingPeriod.endAt).format("L")}`,
        id: accountingPeriod.id,
      })),
    ]);

    const mapDocuments = computed(() => {
      return props.files.map((file) => {
        return {
          accountingPeriod: { period: "Aucune", id: null },
          tags: { text: "Aucune", values: [] },
          realEstateAsset: { name: "Aucun", id: null },
          document: file.name,
          file: file,
        };
      });
    });

    const itemsDocuments = ref([
      ...JSON.parse(JSON.stringify(mapDocuments.value)),
    ]);

    watch(mapDocuments, () => {
      itemsDocuments.value = [...mapDocuments.value];
    });

    const updateItemsDocuments = (
      value: string,
      index: number,
      path: string
    ) => {
      itemsDocuments.value.splice(index, 1, {
        ...itemsDocuments.value[index],
        [path]: value,
      });
    };
    //#endregion

    // #region SupportingDocuments
    const today = getMoment().format("YYYY-MM-DD");
    const headersSupportingDocuments = [
      { text: "N° du justificatif", value: "number" },
      {
        text: "Date d'émission",
        value: "issuanceDate",
      },
      { text: "Libellé de la facture", value: "label" },
      { text: "Prix TTC (facultatif)", value: "amountTotal" },
      { text: "Prix HT (facultatif)", value: "amount" },
      { text: "TVA (facultatif)", value: "amountTax" },
      { text: "Document", value: "document" },
    ];

    const mapSupportingDocuments = computed(() => {
      return props.files.map((file) => {
        return {
          issuanceDate: null,
          label: null,
          amountTotal: 0,
          amount: 0,
          amountTax: 0,
          document: file.name,
          file: file,
        };
      });
    });

    const itemsSupportingDocuments = ref([
      ...JSON.parse(JSON.stringify(mapSupportingDocuments.value)),
    ]);

    watch(
      mapSupportingDocuments,
      () => (itemsSupportingDocuments.value = [...mapSupportingDocuments.value])
    );

    const updateItemsSupportingDocuments = (
      value: string,
      index: number,
      path: string
    ) => {
      itemsSupportingDocuments.value.splice(index, 1, {
        ...itemsSupportingDocuments.value[index],
        [path]: value,
      });
      if (path === "amountTotal" || path === "amount") {
        itemsSupportingDocuments.value.splice(index, 1, {
          ...itemsSupportingDocuments.value[index],
          amountTax: Number(
            new Decimal(
              itemsSupportingDocuments.value[index].amountTotal -
                itemsSupportingDocuments.value[index].amount
            ).toFixed(2)
          ),
        });
      }
    };
    //#endregion

    const mapDocumentData = () => {
      let documentData;
      if (
        context.root.$route.name === ROUTE_NAMES.NewDocuments ||
        context.root.$route.name === ROUTE_NAMES.Documents
      ) {
        documentData = itemsDocuments.value.map((item) => {
          return {
            document: {
              tags: [...item.tags.values],
              product: {
                id: productsStore.currentId,
                accountingPeriod: item.accountingPeriod.id
                  ? { id: item.accountingPeriod.id }
                  : undefined,
                realEstateAsset: item.realEstateAsset.id
                  ? {
                      id: item.realEstateAsset.id,
                    }
                  : undefined,
              },
            },
            file: item.file,
          };
        });
      } else if (context.root.$route.name === ROUTE_NAMES.SupportingDocuments) {
        documentData = itemsSupportingDocuments.value.map((item) => {
          const accountPeriodLinked = accountingPeriodsStore.accountingPeriods
            .filter((period) =>
              getMoment(period.startAt).isSameOrBefore(getMoment(item.value))
            )
            .sort((a, b) => getMoment(a.endAt).diff(getMoment(b.endAt)))[0];

          if (!accountPeriodLinked) {
            throw new Error("Pas de période comptable compatible");
          }
          return {
            document: {
              tags: ["supportingDocument"],
              metadata: {
                wording: item.label,
                issuanceDate: item.issuanceDate,
                amountTotal: item.amountTotal,
                amount: item.amount,
                amountTax: item.amountTax,
              },
              product: {
                id: productsStore.currentId,
                accountingPeriod: { id: accountPeriodLinked.id },
              },
            },
            file: item.file,
          };
        });
      }
      context.emit("updatedData", documentData);
    };

    watch(itemsDocuments, () => mapDocumentData());
    watch(itemsSupportingDocuments, () => mapDocumentData());

    const openFile = (rowIndex: number) => {
      window.open(URL.createObjectURL(props.files[rowIndex]), "_blank");
    };

    return {
      headersDocuments,
      accountingPeriods,
      realEstateAssets,
      tags,
      updateItemsDocuments,
      itemsDocuments,
      today,
      headersSupportingDocuments,
      itemsSupportingDocuments,
      updateItemsSupportingDocuments,
      ROUTE_NAMES,
      openFile,
    };
  },
});
