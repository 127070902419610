import { ref, Ref } from "@vue/composition-api";
import { Document } from "@edmp/api";
import { documentsService } from "@/services";
import { accountingPeriodsStore, productsStore, documentsStore } from "@/store";

export function useDocuments() {
  const documents: Ref<Document[]> = ref([]);

  const sortDocuments = (documents: Document[]): Document[] => {
    return documents.sort((a, b) => {
      if (b.updatedAt && !a.updatedAt) return -1;
      else if (!b.updatedAt && a.updatedAt) return 1;
      else if (b.updatedAt && a.updatedAt)
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      else {
        return 0;
      }
    });
  };

  const getDocuments = async () => {
    try {
      if (productsStore.currentId && accountingPeriodsStore.currentId) {
        // Update all documents in store for this product Id
        await documentsStore.fetchDocuments();
        // Update current documents
        const res = await documentsService.list({
          productId: productsStore.currentId,
        });
        // Filter so that images of the real estate asset are not displayed

        const filteredDocuments = res.filter(
          ((doc) =>
            !doc.product?.accountingPeriod ||
            doc.product.accountingPeriod.id ===
              accountingPeriodsStore.currentId) &&
            ((doc) => !doc.tags?.includes("real-estate-asset-image"))
        );
        documents.value = sortDocuments(filteredDocuments);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  const getProductDocuments = async () => {
    try {
      if (productsStore.currentId) {
        const res = await documentsService.list({
          productId: productsStore.currentId,
        });
        documents.value = sortDocuments(res);
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  return {
    documents,
    getDocuments,
    getProductDocuments,
  };
}
