



















































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import PageHeader from "@/components/atom/PageHeader.vue";
import { ROUTE_NAMES } from "@/router/routes";
import RegularizationInitial from "@/components/core/rentalAgreements/regularizationFlow/RegularizationInitial.vue";
import RegularizationChargeClearance from "@/components/core/rentalAgreements/regularizationFlow/RegularizationChargeClearance.vue";
import RegularizationResult from "@/components/core/rentalAgreements/regularizationFlow/RegularizationResult.vue";

import { accountingPeriodsStore, realEstateAssetsStore } from "@/store";
import { getMoment, PropertyTypeEnum } from "@edmp/api";
import Vue from "vue";
import { useRegularization } from "./regularizationFlow/regularization.usable";

export default defineComponent({
  name: "RegularizationFlow",
  components: {
    RegularizationInitial,
    RegularizationChargeClearance,
    RegularizationResult,
    PageHeader,
  },
  setup(props, context) {
    const {
      itemsBaseFullYearCharge,
      itemsBasePartialYearCommonCharge,
      itemsBasePartialYearIndividualCharge,
    } = useRegularization();

    const isFullYear = computed(() => {
      const periodStartYearMonthDay =
        regularization.value.periodStart.split("-");
      const periodEndYearMonthDay = regularization.value.periodEnd.split("-");
      return (
        // Month
        periodStartYearMonthDay["1"] === "01" &&
        // Day
        periodStartYearMonthDay["2"] === "01" &&
        // Month
        periodEndYearMonthDay["1"] === "12" &&
        // Day
        periodEndYearMonthDay["2"] === "31"
      );
    });

    const step = ref(1);

    const baseData = {
      fullYearCharges: {
        0: { ...itemsBaseFullYearCharge },
        1: { ...itemsBaseFullYearCharge },
        2: { ...itemsBaseFullYearCharge },
        3: { ...itemsBaseFullYearCharge },
      },
      partialYearCommonCharges: {
        0: { ...itemsBasePartialYearCommonCharge },
        1: { ...itemsBasePartialYearCommonCharge },
        2: { ...itemsBasePartialYearCommonCharge },
        3: { ...itemsBasePartialYearCommonCharge },
      },
      partialYearIndividualCharges: {
        0: { ...itemsBasePartialYearIndividualCharge },
        1: { ...itemsBasePartialYearIndividualCharge },
        2: { ...itemsBasePartialYearIndividualCharge },
        3: { ...itemsBasePartialYearIndividualCharge },
        4: { ...itemsBasePartialYearIndividualCharge },
        5: { ...itemsBasePartialYearIndividualCharge },
        6: { ...itemsBasePartialYearIndividualCharge },
        7: { ...itemsBasePartialYearIndividualCharge },
        8: { ...itemsBasePartialYearIndividualCharge },
      },
    };

    const regularization = ref({
      rentalAgreementId: context.root.$route.params.rentalAgreementId,
      periodStart: getMoment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD"),
      periodEnd: getMoment()
        .endOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD"),
      propertyType: PropertyTypeEnum.CO_OWNERSHIP,
      data: { ...JSON.parse(JSON.stringify(baseData)) },
    });

    watch(isFullYear, () => {
      resetRegularization();
    });

    const resetRegularization = () =>
      Vue.set(regularization.value, "data", {
        ...JSON.parse(JSON.stringify(baseData)),
      });

    const redirectToPreviousPage = () => {
      context.root.$router.push({
        name: ROUTE_NAMES.RealEstate,
        query: { tab: accountingPeriodsStore.isIR ? "1" : "2" },
      });
    };

    const validate = async () => {
      realEstateAssetsStore.selectRealEstateAssetId("");
      redirectToPreviousPage();
    };

    const updateRegularizationField = (value: string, path: string) => {
      const pList = path.split(".");
      const key = pList.pop();
      const pointer = pList.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue] === undefined) {
          Vue.set(accumulator, currentValue, {});
        }
        return accumulator[currentValue];
      }, regularization.value);
      Vue.set(pointer, key as string, value);
    };

    watch(
      regularization,
      () => {
        if (isFullYear.value) {
          for (const key in Object.keys(
            regularization.value.data.fullYearCharges
          )) {
            if (
              key !==
              Object.keys(
                regularization.value.data.fullYearCharges
              ).length.toString()
            ) {
              const result = (
                regularization.value.data.fullYearCharges[key].expenses *
                (regularization.value.data.fullYearCharges[key].percentage /
                  100)
              ).toFixed(2);
              Vue.set(
                regularization.value.data.fullYearCharges[key],
                "totalAmount",
                result
              );
            }
          }
        } else {
          const periodDays = getMoment(regularization.value.periodEnd)
            .add(1, "day")
            .diff(getMoment(regularization.value.periodStart), "days");

          for (const key in Object.keys(
            regularization.value.data.partialYearCommonCharges
          )) {
            if (
              key !==
              Object.keys(
                regularization.value.data.partialYearCommonCharges
              ).length.toString()
            ) {
              const proration = periodDays / 365;
              const result = (
                regularization.value.data.partialYearCommonCharges[key]
                  .expenses *
                proration *
                (regularization.value.data.partialYearCommonCharges[key]
                  .percentage /
                  100)
              ).toFixed(2);
              Vue.set(
                regularization.value.data.partialYearCommonCharges[key],
                "totalAmount",
                result
              );
            }
          }
          for (const key in Object.keys(
            regularization.value.data.partialYearIndividualCharges
          )) {
            if (
              key !==
              Object.keys(
                regularization.value.data.partialYearIndividualCharges
              ).length.toString()
            ) {
              if (
                regularization.value.data.partialYearIndividualCharges[key]
                  .indexStart &&
                regularization.value.data.partialYearIndividualCharges[key]
                  .indexEnd
              ) {
                regularization.value.data.partialYearIndividualCharges[
                  key
                ].consumption =
                  regularization.value.data.partialYearIndividualCharges[key]
                    .indexEnd -
                  regularization.value.data.partialYearIndividualCharges[key]
                    .indexStart;

                const result = (
                  regularization.value.data.partialYearIndividualCharges[key]
                    .consumption *
                  regularization.value.data.partialYearIndividualCharges[key]
                    .unitCost *
                  (regularization.value.data.partialYearIndividualCharges[key]
                    .percentage /
                    100)
                ).toFixed(2);
                Vue.set(
                  regularization.value.data.partialYearIndividualCharges[key],
                  "totalAmount",
                  result
                );
              }
            }
          }
        }
      },
      { deep: true }
    );

    return {
      step,
      validate,
      ROUTE_NAMES,
      regularization,
      isFullYear,
      updateRegularizationField,
      redirectToPreviousPage,
      accountingPeriodsStore,
      resetRegularization,
    };
  },
});
