















































import { defineComponent, onMounted } from "@vue/composition-api";
import DocumentsHeader from "./DocumentsHeader.vue";
import DocumentCard from "./DocumentCard.vue";
import { useDocuments } from "./useDocuments";
import { documentsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "NewDocuments",
  components: {
    DocumentsHeader,
    DocumentCard,
  },
  setup() {
    const { getProductDocuments } = useDocuments();

    onMounted(() => {
      documentsStore.fetchDocuments();
    });

    return { ROUTE_NAMES, documentsStore, getProductDocuments };
  },
});
