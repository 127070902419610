






























































































import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import {
  getMoment,
  TaxDeclaration,
  TaxDeclarationProviderTeledecModel,
  TaxDeclarationsService,
  TaxDeclarationStatus,
  TaxRegime,
} from "@edmp/api";
import { extension } from "mime-types";
import { cloneDeep, merge } from "lodash";

import { accountingPeriodsStore, documentsStore, usersStore } from "@/store";
import { taxDeclarationsService } from "@/services/TaxDeclarations.service";

import PageHeader from "@/components/atom/PageHeader.vue";
import TeletransmitFollowUpError from "./TeletransmitFollowUpErrors.vue";
import EditableTable, {
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";

export default defineComponent({
  name: "TeletransmitFollowUp",
  components: {
    PageHeader,
    EditableTable,
    TeletransmitFollowUpError,
  },
  props: {},
  setup() {
    const taxDeclarations: Ref<TaxDeclarationsService.ListOut> = ref([]);
    const documents = computed(() =>
      documentsStore.documents.filter(
        (document) =>
          document.tags?.includes("tax") &&
          document.createdBy === "ownily" &&
          document.product?.accountingPeriod?.id ===
            accountingPeriodsStore.currentId
      )
    );
    const user = computed(() => usersStore.loggedInUser);

    const taxDeclarationsHeaders: ComputedRef<TableHeaderItems> = computed(
      () => [
        { text: "Date de validation", value: "createdAt", width: "auto" },
        { text: "Date de l'envoie", value: "sendAt", width: "auto" },
        { text: "Validité de la liasse", value: "validation", width: "auto" },
        { text: "Document", value: "document", width: "auto" },
      ]
    );
    const taxDeclarationsItems = computed(() => {
      const taxDeclarationsItemsOut = (
        cloneDeep(taxDeclarations.value).filter(
          (t) =>
            t.status === TaxDeclarationStatus.Processing ||
            t.status === TaxDeclarationStatus.Success ||
            t.status === TaxDeclarationStatus.Error
        ) as TaxDeclaration<
          TaxRegime,
          | TaxDeclarationStatus.Processing
          | TaxDeclarationStatus.Success
          | TaxDeclarationStatus.Error,
          boolean,
          true
        >[]
      ).map((taxDeclaration) =>
        merge(taxDeclaration, {
          createdAt: getMoment(taxDeclaration.createdAt).format("LLLL"),
          sendAt: taxDeclaration.wasSendToDgfip
            ? getMoment(taxDeclaration.sendAt).format("LLLL")
            : "Non envoyé",
          validation: (() => {
            if (taxDeclaration.wasSendToDgfip) {
              switch (taxDeclaration.status) {
                case TaxDeclarationStatus.Processing:
                  return {
                    status: taxDeclaration.status,
                    text: "En cours de traitement",
                    icon: "mdi-cached",
                    iconColor: "primary",
                  };

                case TaxDeclarationStatus.Success:
                  return {
                    status: taxDeclaration.status,
                    text: `Acceptée par la DGFIP le ${getMoment(
                      taxDeclaration.dgfip?.dateHeureDGFiP
                    ).format("LLLL")}`,
                    icon: "mdi-check-circle",
                    iconColor: "success",
                  };

                case TaxDeclarationStatus.Error:
                  return {
                    status: taxDeclaration.status,
                    text: `Rejeté le ${getMoment(
                      taxDeclaration.dgfip?.dateHeureDGFiP
                    ).format("LLLL")}`,
                    icon: "mdi-close-circle",
                    iconColor: "error",
                  };
              }
            }
            return {
              status: taxDeclaration.status,
              text: "",
            };
          })(),
          document: documents.value.find(
            (d) => taxDeclaration.documentPdfCerfaId === d.id
          ),
        })
      );
      return taxDeclarationsItemsOut;
    });
    const taxDeclarationError: Ref<{
      isOpen: boolean;
      isOpenCalendly: boolean;
      taxDeclaration?: TaxDeclaration<
        TaxRegime,
        TaxDeclarationStatus.Error,
        boolean,
        true
      > & {
        dgfip: {
          status: TaxDeclarationProviderTeledecModel.WebHook.STATUS.ERROR;
        };
      };
    }> = ref({ isOpen: false, isOpenCalendly: false });

    // Methods
    const showTaxDeclarationError = (index: number) => {
      taxDeclarationError.value.taxDeclaration = taxDeclarationsItems.value[
        index
      ] as TaxDeclaration<
        TaxRegime,
        TaxDeclarationStatus.Error,
        boolean,
        true
      > & {
        dgfip: {
          status: TaxDeclarationProviderTeledecModel.WebHook.STATUS.ERROR;
        };
      };
      taxDeclarationError.value.isOpen = true;
    };

    // Mutation
    watch(
      () => accountingPeriodsStore.currentId,
      async () => await init(),
      { deep: true }
    );

    // Init
    const init = async () => {
      documentsStore.fetchDocuments();
      taxDeclarations.value = await taxDeclarationsService.list({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });
    };

    onBeforeMount(async () => {
      await init();
    });

    return {
      taxDeclarations,
      taxDeclarationsHeaders,
      taxDeclarationsItems,
      TaxDeclarationStatus,
      extension,
      showTaxDeclarationError,
      taxDeclarationError,
      user,
    };
  },
});
