var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{staticClass:"documentsPanel mb-4",attrs:{"value":_vm.opened ? 0 : ''}},[_c('v-expansion-panel',{staticClass:"pa-sm-2 pa-md-4"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v("$expand")])]},proxy:true}])},[_c('h3',{staticClass:"documents-section-title"},[_vm._v(_vm._s(_vm.sectionName))])]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"documents-table",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.documents,"items-per-page":10,"no-data-text":"Aucun document"},on:{"click:row":_vm.showDocument},scopedSlots:_vm._u([{key:"item.new",fn:function(ref){
var item = ref.item;
return [(item.new)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("New")]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"document-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.createdAt), "short", "fr-FR"))+" ")])]}},{key:"item.realEstateAsset",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.product.realEstateAsset ? _vm.getRealEstateAsset(item.product.realEstateAsset.id) : "")+" ")])]}},{key:"item.rentalAgreement",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"document-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.product.realEstateAsset && item.product.realEstateAsset.rentalAgreement ? _vm.getRentalAgreement( item.product.realEstateAsset.rentalAgreement.id ) : "")+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.product.realEstateAsset && item.product.realEstateAsset.rentalAgreement ? _vm.getRentalAgreement( item.product.realEstateAsset.rentalAgreement.id ) : ""))])])],1)]}},{key:"item.rentPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.rentPeriod)+" ")])]}},{key:"item.accountingPeriod",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getAccountingPeriodYear(item.product.accountingPeriod))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"document-type pa-3",attrs:{"depressed":"","color":"#757575","href":item.href,"target":item.id}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(item.type))+" ")])]}}],null,true)},[_c('span',[_vm._v("Voir le document")])])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[(item.tags[0] !== 'real-estate-assets-image')?_c('v-btn',{staticClass:"ml-5",attrs:{"depressed":"","x-small":"","height":"22","width":"22","fab":"","color":"#F4655F","disabled":item.createdBy === 'ownily'},on:{"click":function($event){return _vm.deleteDocument(item)}}},[_c('v-icon',{attrs:{"small":"","color":"#fff"}},[_vm._v("mdi-close")])],1):_vm._e()],1)]}}],null,true)}),_c('Confirm',{ref:"confirmDialog"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }