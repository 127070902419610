import { ref, Ref, watch } from "@vue/composition-api";
import { Document, TypeReference, BalanceCategories } from "@edmp/api";
import {
  productsStore,
  documentsStore,
  accountingsStore,
  accountingPeriodsStore,
} from "@/store";

export function useSupportingDocuments() {
  const supportingDocuments: Ref<Document[]> = ref([]);

  const sortDocuments = (supportingDocuments: Document[]): Document[] => {
    return supportingDocuments.sort((a, b) => {
      if (b.updatedAt && !a.updatedAt) return -1;
      else if (!b.updatedAt && a.updatedAt) return 1;
      else if (b.updatedAt && a.updatedAt)
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      else {
        return 0;
      }
    });
  };

  const isReconciled = (
    balanceCategories: BalanceCategories,
    docId: string
  ): boolean => {
    if (!balanceCategories) {
      return false;
    }
    return balanceCategories.categories
      .flatMap((category) =>
        category.yearly.flatMap((yearlyCategory) =>
          yearlyCategory.monthly.flatMap((monthlyCategory) =>
            monthlyCategory.daily.flatMap(
              (dailyCategory) => dailyCategory.categorizations
            )
          )
        )
      )
      .some((categorization) =>
        categorization.refs?.some(
          (ref) =>
            ref.type === TypeReference.supportingDocument &&
            ref.referredId === docId
        )
      );
  };

  const getSupportingDocuments = async () => {
    try {
      if (productsStore.currentId) {
        // Retrieve balanceCategories to retrieve the reconciliation status of each document
        const balanceCategories = await accountingsStore.getBalanceCategories(
          accountingPeriodsStore.currentId
        );

        const documents = documentsStore.documents.filter((doc) =>
          doc.tags?.includes("supportingDocument")
        );

        supportingDocuments.value = sortDocuments(documents).map((doc) => {
          if (!doc.metadata) doc.metadata = {};

          const status = isReconciled(balanceCategories, doc.id)
            ? "Réconcilié"
            : "En cours";
          (doc as Document & { metadata: { status: string } }).metadata.status =
            status;

          return doc;
        });
      }
    } catch (err) {
      console.error("error", err);
    }
  };

  watch(
    () => documentsStore.documents,
    () => getSupportingDocuments()
  );

  return {
    supportingDocuments,
    getSupportingDocuments,
  };
}
