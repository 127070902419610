var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$route.name === _vm.ROUTE_NAMES.NewDocuments ||
      _vm.$route.name === _vm.ROUTE_NAMES.Documents
    )?_c('EditableTable',{attrs:{"headers":_vm.headersDocuments,"items":_vm.itemsDocuments,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headersDocuments),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var value = ref.value;
    var rowIndex = ref.rowIndex;
return [(header.value === 'number')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(rowIndex + 1)+" ")]):_vm._e(),(header.value === 'accountingPeriod')?_c('v-select',{key:header.value,attrs:{"value":_vm.itemsDocuments[rowIndex].accountingPeriod,"items":_vm.accountingPeriods,"item-text":"period","item-value":"id","return-object":""},on:{"change":function (event) { return _vm.updateItemsDocuments(event, rowIndex, 'accountingPeriod'); }}}):(header.value === 'tags')?_c('v-select',{key:header.value,attrs:{"value":_vm.itemsDocuments[rowIndex].tags,"items":_vm.tags,"item-text":"text","item-value":"values","return-object":""},on:{"change":function (event) { return _vm.updateItemsDocuments(event, rowIndex, 'tags'); }}}):(header.value === 'realEstateAsset')?_c('v-select',{key:header.value,attrs:{"value":_vm.itemsDocuments[rowIndex].realEstateAsset,"items":_vm.realEstateAssets,"item-text":"name","item-value":"id","return-object":""},on:{"change":function (event) { return _vm.updateItemsDocuments(event, rowIndex, 'realEstateAsset'); }}}):_vm._e(),(header.value === 'document')?_c('div',{key:header.value,staticClass:"link",on:{"click":function($event){return _vm.openFile(rowIndex)}}},[_vm._v(" "+_vm._s(value)+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1):_vm._e()]}}})],null,true)}):(_vm.$route.name === _vm.ROUTE_NAMES.SupportingDocuments)?_c('EditableTable',{attrs:{"headers":_vm.headersSupportingDocuments,"items":_vm.itemsSupportingDocuments,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headersSupportingDocuments),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var value = ref.value;
    var rowIndex = ref.rowIndex;
return [(header.value === 'number')?_c('div',{key:header.value},[_vm._v(" "+_vm._s(rowIndex + 1)+" ")]):(header.value === 'issuanceDate')?_c('DatePicker',{key:header.value,attrs:{"value":_vm.itemsSupportingDocuments[rowIndex].issuanceDate,"label":"Date d'émission","max":_vm.today,"rules":[
          function () { return !!_vm.itemsSupportingDocuments[rowIndex].issuanceDate ||
            'Le champ ne peut pas être vide'; } ],"required":""},on:{"input":function (event) { return _vm.updateItemsSupportingDocuments(event, rowIndex, 'issuanceDate'); }}}):_vm._e(),(header.value === 'label')?_c('v-text-field',{key:header.value,attrs:{"value":_vm.itemsSupportingDocuments[rowIndex].label,"rules":[
          function () { return !!_vm.itemsSupportingDocuments[rowIndex].label ||
            'Le champ ne peut pas être vide'; } ],"required":""},on:{"input":function (event) { return _vm.updateItemsSupportingDocuments(event, rowIndex, 'label'); }}}):(header.value === 'amountTotal')?_c('v-text-field',{key:header.value,attrs:{"type":"number","value":_vm.itemsSupportingDocuments[rowIndex].amountTotal,"append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.updateItemsSupportingDocuments(event, rowIndex, 'amountTotal'); }}}):(header.value === 'amount')?_c('v-text-field',{key:header.value,attrs:{"type":"number","value":_vm.itemsSupportingDocuments[rowIndex].amount,"append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.updateItemsSupportingDocuments(event, rowIndex, 'amount'); }}}):(header.value === 'amountTax')?_c('v-text-field',{key:header.value,attrs:{"type":"number","value":_vm.itemsSupportingDocuments[rowIndex].amountTax,"append-icon":"mdi-currency-eur"},on:{"input":function (event) { return _vm.updateItemsSupportingDocuments(event, rowIndex, 'amountTax'); }}}):_vm._e(),(header.value === 'document')?_c('div',{key:header.value,staticClass:"link",on:{"click":function($event){return _vm.openFile(rowIndex)}}},[_vm._v(" "+_vm._s(value)+" "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-open-in-new")])],1):_vm._e()]}}})],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }