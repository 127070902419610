import { render, staticRenderFns } from "./TaxDeclaration2031.vue?vue&type=template&id=0dfa61f0&"
import script from "./TaxDeclaration2031.vue?vue&type=script&lang=ts&"
export * from "./TaxDeclaration2031.vue?vue&type=script&lang=ts&"
import style0 from "./TaxDeclaration2031.vue?vue&type=style&index=0&id=0dfa61f0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
