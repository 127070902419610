import { render, staticRenderFns } from "./Transactions.vue?vue&type=template&id=c1f37978&scoped=true&"
import script from "./Transactions.vue?vue&type=script&lang=ts&"
export * from "./Transactions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1f37978",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VAlert,VDialog,VSkeletonLoader})
