import store from "@/store/root";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { documentsService } from "@/services";
import { Document, DocumentsService } from "@edmp/api";
import { productsStore } from "..";

export interface DocumentsState {
  documents: Array<Document>;
  isLoading: boolean;
}

@Module({
  name: "documents-store",
  dynamic: true,
  namespaced: true,
  store,
})
export class DocumentsStore extends VuexModule implements DocumentsState {
  documents: Array<Document> = [];
  isLoading = false;
  previousArrayLength = 0;

  @Mutation
  reset(): void {
    this.documents = [];
    this.isLoading = false;
  }

  get getDocument() {
    return (documentId: string): Document | undefined => {
      return this.documents.find(({ id }) => id === documentId);
    };
  }

  get getDocumentByTransaction() {
    return (transactionId: string): Document[] | undefined => {
      return this.documents.filter(
        (document) => document.bankAccount?.transaction?.id === transactionId
      );
    };
  }

  @Mutation
  setDocuments(documents: Document[]): void {
    this.documents = documents;
  }

  @Mutation
  addDocument(document: Document & { new?: boolean }): void {
    this.documents.push(document);
  }

  @Mutation
  removeDocument(documentId: string) {
    const documentIndex = this.documents.findIndex(
      (document) => document.id === documentId
    );
    if (documentIndex !== -1) {
      this.documents.splice(documentIndex, 1);
    }
  }

  @Mutation
  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @Action
  async fetchDocuments(): Promise<void> {
    this.setIsLoading(true);
    this.setDocuments(
      await documentsService.list({ productId: productsStore.currentId })
    );
    this.setIsLoading(false);
  }

  @Action
  async createDocument(
    documentCreate: Omit<DocumentsService.CreateIn, "file"> & { file: File }
  ): Promise<Document & { new: Boolean }> {
    this.setIsLoading(true);
    const newDocument = await documentsService.create(documentCreate);
    this.addDocument({ ...newDocument, new: true });
    this.setIsLoading(false);
    return { ...newDocument, new: true };
  }

  @Action
  async addTransactionAttachment(
    documentCreate: Omit<
      DocumentsService.AddAttachmentTransactionIn,
      "file"
    > & {
      file: File;
    }
  ): Promise<Document> {
    const attachment = await documentsService.addTransactionAttachment(
      documentCreate
    );
    this.addDocument(attachment);
    return attachment;
  }
  // Add Documents from Real Estate Asset
  @Action
  async addDocuments(documentId: string) {
    this.setIsLoading(true);
    const document = await documentsService.get({ id: documentId });
    this.addDocument(document);
    this.setIsLoading(false);
  }
  @Action
  async deleteDocument(id: string) {
    this.setIsLoading(true);
    const isDeleted = await documentsService.remove({ id });
    if (isDeleted) {
      this.removeDocument(id);
    }
    this.setIsLoading(false);
  }
}
