


















































import {
  defineComponent,
  onMounted,
  watch,
  Ref,
  ref,
} from "@vue/composition-api";
import { HelpingMessage } from "@/components/atom/helping";
import SupportingDocumentsPanel from "./SupportingDocumentsPanel.vue";
import PageHeader from "../../atom/PageHeader.vue";
import { useSupportingDocuments } from "./supportingDocuments.usable";
import { accountingPeriodsStore } from "@/store";
import { ArticleEnum } from "@/composables/crisp.usable";

import { TableHeaderItems } from "@/components/atom/table/EditableTable.vue";
import UploadFlowDialog from "@/components/atom/UploadFlowDialog.vue";
import TitleNew from "../title/TitleNew.vue";

export default defineComponent({
  name: "SupportingDocuments",
  components: {
    HelpingMessage,
    PageHeader,
    SupportingDocumentsPanel,
    UploadFlowDialog,
    TitleNew,
  },
  setup() {
    const headers: TableHeaderItems = [
      { text: "", value: "new" },
      { text: "Statut", value: "metadata.status" },
      { text: "Date d'émission", value: "metadata.issuanceDate" },
      { text: "Libellé de la facture", value: "metadata.wording" },
      { text: "Montant TTC", value: "metadata.amountTotal" },
      { text: "Montant HT", value: "metadata.amount" },
      { text: "Montant TVA", value: "metadata.amountTax" },
      { text: "Document", value: "type" },
    ];

    const openUploadFlowDialog: Ref<boolean> = ref(false);

    const { supportingDocuments, getSupportingDocuments } =
      useSupportingDocuments();

    async function init() {
      supportingDocuments.value = [];
      getSupportingDocuments();
    }

    onMounted(() => {
      init();
    });
    watch(
      () => accountingPeriodsStore.currentId,
      () => init(),
      { deep: true }
    );

    return {
      supportingDocuments,
      getSupportingDocuments,
      headers,
      openUploadFlowDialog,
      ArticleEnum,
    };
  },
});
