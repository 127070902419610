import { Document, model, Schema } from "mongoose";
import { ulid } from "ulid";
import { DocumentFileType, ProductsModel, RequireField, SubscriptionsModel, IsoDate, Address, addressSchema, OneOf, Tenant } from "..";
import { RealEstateAsset } from "./RealEstateAsset.model";
import { RentalUnit } from "./RentalUnit.model";

export enum DocumentModelType {
  RENT_RECEIPT = "Quittance de loyer",
  RENT_RECEIPT_WITH_VAT = "Quittance de loyer avec TVA",
  RENT_RECEIPT_WITH_APL = "Quittance de loyer avec APL",
  RENT_RECEIPT_WITH_VAT_AND_APL = "Quittance de loyer avec TVA et APL",

  RENT_CALL = "Appel de loyer",
  RENT_CALL_WITH_VAT = "Appel de loyer avec TVA",
  RENT_CALL_WITH_APL = "Appel de loyer avec APL",
  RENT_CALL_WITH_VAT_AND_APL = "Appel de loyer avec TVA et APL",

  NOTICE_ADJUSTMENT_OF_CHARGES_LESSOR = "Préavis de régularisation des charges - Bailleur",
  NOTICE_ADJUSTMENT_OF_CHARGES_TENANT = "Préavis de régularisation des charges - Locataire",
  NOTICE_RENT_REVIEW = "Préavis de révision du loyer",

  MOBILITY_LEASE = "Bail mobilité",
  FURNISHED_LEASE = "Bail location meublé", 
  UNFURNISHED_LEASE = "Bail location nue", 
  
  FORMAL_NOTICE = "Mise en demeure", 

  REMINDER_INSURANCE_CERTIFICATE = "Relance attestation d'assurance habitation",
  REMINDER_OF_UNPAID_RENT = "Relance de loyer impayé",

  PARTIAL_RENT_RECEIPT = "Reçu de loyer",

  DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_A_FIXED_TERM = "Acte de cautionnement solidaire à durée déterminée",
  DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_AN_INDEFINITE_TERM = "Acte de cautionnement solidaire à durée indéterminée", 
  DEED_OF_SIMPLE_SURETY_FOR_A_FIXED_TERM = "Acte de cautionnement simple à durée déterminée", 
  DEED_OF_SIMPLE_SURETY_FOR_AN_INDEFINITE_TERM = "Acte de cautionnement simple à durée indéterminée",

  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT = "Avenant au bail - Arrivée d'un nouveau locataire",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité",
  LEASE_AMENDMENT_CHANGE_OF_TENANT = "Avenant au bail - Changement de locataire",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT= "Avenant au bail - Départ d'un locataire",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE = "Avenant au bail - Prolongation d'un bail mobilité",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE = "Avenant au bail - Décalage de date de paiement",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD = "Avenant au bail - Franchise temporaire de loyer",

  // Lessor - Natural Person / Tenant - Natural Person
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Changement de locataire - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Départ d'un locataire - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Décalage de date de paiement - Bailleur Particulier - Locataire Particulier",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_NAT_PERS_TENANT_NAT_PERS = "Avenant au bail - Franchise temporaire de loyer - Bailleur Particulier - Locataire Particulier",

  // Lessor - Company / Tenant - Natural Person
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Changement de locataire - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Départ d'un locataire - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Décalage de date de paiement - Bailleur Société - Locataire Particulier",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_COMPANY_TENANT_NAT_PERS = "Avenant au bail - Franchise temporaire de loyer - Bailleur Société - Locataire Particulier",

  // Lessor - Natural Person / Tenant - Company
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Changement de locataire - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Départ d'un locataire - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Décalage de date de paiement - Bailleur Particulier - Locataire Société",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_NAT_PERS_TENANT_COMPANY = "Avenant au bail - Franchise temporaire de loyer - Bailleur Particulier - Locataire Société",

  // Lessor - Company / Tenant - Company
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Changement de locataire - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Départ d'un locataire - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Décalage de date de paiement - Bailleur Société - Locataire Société",
  LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_COMPANY_TENANT_COMPANY = "Avenant au bail - Franchise temporaire de loyer - Bailleur Société - Locataire Société",
  
  STATE_OF_PLAY = "État des lieux",
  STATE_OF_PLAY_HOUSE = "État des lieux - Maison",
  STATE_OF_PLAY_STUDIO_T1 = "État des lieux - Studio - T1",
  STATE_OF_PLAY_T2_T3 = "État des lieux - T2 - T3",
  STATE_OF_PLAY_T3_T4 = "État des lieux - T4 - T5",
  STATE_OF_PLAY_COMMERCIAL = "État des lieux - Commercial",

  
}

// const documentName = (key: string) => {   
//   const correspondances = {
 export const modelTypeMapping: { [key: string]: DocumentModelType } = {
  "Quittance de loyer": DocumentModelType.RENT_RECEIPT,
  "Quittance de loyer avec TVA": DocumentModelType.RENT_RECEIPT_WITH_VAT,
  "Quittance de loyer avec APL": DocumentModelType.RENT_RECEIPT_WITH_APL,
  "Quittance de loyer avec TVA et APL" : DocumentModelType.RENT_RECEIPT_WITH_VAT_AND_APL,

  "Appel de loyer" : DocumentModelType.RENT_CALL,
  "Appel de loyer avec TVA" : DocumentModelType.RENT_CALL_WITH_VAT,
  "Appel de loyer avec APL": DocumentModelType.RENT_CALL_WITH_APL,
  "Appel de loyer avec TVA et APL" : DocumentModelType.RENT_CALL_WITH_VAT_AND_APL,

  "Préavis de régularisation des charges - Bailleur" : DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_LESSOR,
  "Préavis de régularisation des charges - Locataire" : DocumentModelType.NOTICE_ADJUSTMENT_OF_CHARGES_TENANT, 
  "Préavis de révision du loyer" : DocumentModelType.NOTICE_RENT_REVIEW,

  "Bail mobilité" : DocumentModelType.MOBILITY_LEASE,
  "Bail location meublé" : DocumentModelType.FURNISHED_LEASE, 
  "Bail location nue" : DocumentModelType.UNFURNISHED_LEASE, 
  
  "Mise en demeure"  : DocumentModelType.FORMAL_NOTICE, 

  "Relance attestation d'assurance habitation" : DocumentModelType.REMINDER_INSURANCE_CERTIFICATE,
  "Relance de loyer impayé": DocumentModelType.REMINDER_OF_UNPAID_RENT,

  "Reçu de loyer" : DocumentModelType.PARTIAL_RENT_RECEIPT,

  "Acte de cautionnement solidaire à durée déterminée"  : DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_A_FIXED_TERM, 
  "Acte de cautionnement solidaire à durée indéterminée"  : DocumentModelType.DEED_OF_JOINT_AND_SEVERAL_SURETY_FOR_AN_INDEFINITE_TERM, 
  "Acte de cautionnement simple à durée déterminée" : DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_A_FIXED_TERM, 
  "Acte de cautionnement simple à durée indéterminée" : DocumentModelType.DEED_OF_SIMPLE_SURETY_FOR_AN_INDEFINITE_TERM,

  // Lessor - Natural Person / Tenant - Natural Person
  "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Particulier - Locataire Particulier" : DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Particulier - Locataire Particulier" : DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Changement de locataire - Bailleur Particulier - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Départ d'un locataire - Bailleur Particulier - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Particulier - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Décalage de date de paiement - Bailleur Particulier - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_NAT_PERS_TENANT_NAT_PERS,
  "Avenant au bail - Franchise temporaire de loyer - Bailleur Particulier - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_NAT_PERS_TENANT_NAT_PERS,

  // Lessor - Company / Tenant - Natural Person
  "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Changement de locataire - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Départ d'un locataire - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Décalage de date de paiement - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_COMPANY_TENANT_NAT_PERS,
  "Avenant au bail - Franchise temporaire de loyer - Bailleur Société - Locataire Particulier": DocumentModelType.LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_COMPANY_TENANT_NAT_PERS,

  // Lessor - Natural Person / Tenant - Company
  "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Changement de locataire - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Départ d'un locataire - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Décalage de date de paiement - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_NAT_PERS_TENANT_COMPANY,
  "Avenant au bail - Franchise temporaire de loyer - Bailleur Particulier - Locataire Société": DocumentModelType.LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_NAT_PERS_TENANT_COMPANY,

  // Lessor - Company / Tenant - Company
  "Avenant au bail - Arrivée d'un nouveau locataire - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Arrivée d'un nouveau locataire avec clause de solidarité - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_ARRIVAL_OF_NEW_TENANT_WITH_SOLIDARITY_CLAUSE_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Changement de locataire - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_CHANGE_OF_TENANT_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Départ d'un locataire - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_DEPARTURE_OF_A_TENANT_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Prolongation d'un bail mobilité - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_EXTENSION_OF_A_MOBILITY_LEASE_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Décalage de date de paiement - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_PAYMENT_DATE_CHANGE_LESSOR_COMPANY_TENANT_COMPANY,
  "Avenant au bail - Franchise temporaire de loyer - Bailleur Société - Locataire Société": DocumentModelType.LEASE_AMENDMENT_TEMPORARY_RENT_FREE_PERIOD_LESSOR_COMPANY_TENANT_COMPANY,


  "État des lieux - Maison":  DocumentModelType.STATE_OF_PLAY_HOUSE,
  "État des lieux - Studio - T1":  DocumentModelType.STATE_OF_PLAY_STUDIO_T1,
  "État des lieux - T2 - T3":  DocumentModelType.STATE_OF_PLAY_T2_T3,
  "État des lieux - T4 - T5":  DocumentModelType.STATE_OF_PLAY_T3_T4,
  "État des lieux - Commercial":  DocumentModelType.STATE_OF_PLAY_COMMERCIAL,
  }


export const isRentReceiptModelType = (modelType: DocumentModelType): boolean =>
  [
    DocumentModelType.RENT_RECEIPT,
    DocumentModelType.RENT_RECEIPT_WITH_VAT,
    DocumentModelType.RENT_RECEIPT_WITH_APL,
    DocumentModelType.RENT_RECEIPT_WITH_VAT_AND_APL,
  ].includes(modelType);

export const isRentCallModelType = (modelType: DocumentModelType): boolean =>
  [
    DocumentModelType.RENT_CALL,
    DocumentModelType.RENT_CALL_WITH_VAT,
    DocumentModelType.RENT_CALL_WITH_APL,
    DocumentModelType.RENT_CALL_WITH_VAT_AND_APL,
  ].includes(modelType);

export interface RentalAgreementsFilter {
  realEstateAssetId?: string;
  rentalAgreementActive?: boolean;
}

export type RentalAgreementOptions = {
  rentCallActivated: boolean;
} & {
  rentReceiptActivated: boolean;
  reminderUnpaidRentActivated: boolean;
  unpaidRentCallHasToCopyActivity: boolean;
} & (
    | {
        rentCallHasToCopyActivity: false;
        rentCallNumberDayBeforeRent?: number;
        rentPaymentDayOfTheMonth?: number;
      }
    | {
        rentCallHasToCopyActivity: true;
        rentCallNumberDayBeforeRent: number;
        rentPaymentDayOfTheMonth: number;
      }
  ) &
  (
    | {
        rentReceiptHasToCopyActivity: false;
        reminderRentCallNumberDayAfter?: number;
      }
    | {
        rentReceiptHasToCopyActivity: true;
        reminderRentCallNumberDayAfter: number;
      }
  );

export type RentalAgreementOptionsDocument = RentalAgreementOptions & Document<string>;

const RentalAgreementOptionsSchema = new Schema<RentalAgreementOptionsDocument>(
  {
    rentCallActivated: Boolean,
    rentReceiptActivated: Boolean,
    reminderUnpaidRentActivated: Boolean,
    rentCallHasToCopyActivity: Boolean,
    rentCallNumberDayBeforeRent: Number,
    rentPaymentDayOfTheMonth: Number,
    rentReceiptHasToCopyActivity: Boolean,
    reminderRentCallNumberDayAfter: Number,
    unpaidRentCallHasToCopyActivity: Boolean,
  },
  { _id: false }
);

export type RentReceiptDetails = {
  rentAmount?: number;
  rentAmountCharge?: number;
  rentAmountTVA?: number;
  rentAmountTotal?: number;
};

export type RentReceiptDetailsDocument = RentReceiptDetails & Document<string>;

export enum RentalAgreementType {
  BARE = "bare",
  FURNISHED = "furnished"
}

export interface TenseArea {
  monthlyRentReference: number;
  monthlyRentReferenceIncreased: number;
  supplementRent?: number;
  supplementRentJustification?: string;
}

export enum PaymentTermsEnum {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  HalfYearly = 'half-yearly'
}

export enum RentPayedForEnum {
  UpcomingMonth = 'upcoming-month',
  PastMonth = 'past-month',
  Other = 'other'
}

export enum ChargePaymentMethodEnum {
  ProvisionOnCharge = 'provision-on-charge',
  PeriodicallyWithoutRegulation = 'periodically-without-regulation',
  AbsenceOfCharge = 'absence-of-charge',
  FixedAmount = 'fixed-amount'
}

export enum RentIndexationTypeParamEnum {
  IRL_metro = 'metro',
  IRL_corse = 'corsica',
  IRL_outre = 'overseas',
  ILAT = "ilat",
  ILC = "ilc",
  ICC = "icc",
}

export enum QuarterPeriodEnum {
  T1 = "T1",
  T2 = "T2",
  T3 = "T3",
  T4 = "T4",
}

export enum PropertyTypeEnum {
  CO_OWNERSHIP = "Copropriété",
  SINGLE_OWNERSHIP = "Mono-propriété",
  WITHOUT_COLLECTIVITY = "Sans collectivité"
}

export interface Indexation {
  type: RentIndexationTypeParamEnum;
  index: string;
}

export interface FinancialConditions {
  tenseArea?: TenseArea;
  rentCharge: number;
  rentGuaranteeDeposit: number;
  amountOfCharges: number;
  chargePaymentMethod: ChargePaymentMethodEnum;
  paymentTerms: PaymentTermsEnum;
  rentPayedFor: RentPayedForEnum;
  amountOfTVA?: number;
  indexation?: Indexation;
}

export interface NewRentalAgreement {
  product: {
    id: string;
    realEstateAsset?: {
      id: string;
      rentalUnit?: {
        id: string;
      };
    };
  };
  name: string;
  type: RentalAgreementType;
  startAt: IsoDate;
  endAt?: IsoDate;
  options?: RentalAgreementOptions;
  financialConditions: FinancialConditions;
}

/**
 * `RentalAgreement` — A rental agreement
 */
export type StatsRentalAgreementsWithNotSendNotificationRentReceipt =
  | {
      userId: string | null;
      userEmail: string | null;
      productId: string;
      productName: string | null;
      subscriptionPlan: SubscriptionsModel.PlanType;
      subscriptionEndAt: string;
      realEstateAssetId: string | null;
      realEstateAssetName: string | null;
      tenantFirstName: string | null;
      tenantLastName: string | null;
      tenantDenomination: string | null;
      sendRentReceiptEnable: boolean;
      "sendRentReceipt.error.message"?: string;
      "sendRentReceipt.success"?: boolean;
      [key: `transaction.${number}.id`]: string;
      [key: `transaction.${number}.date`]: string;
    }
  | { noData: "No data" };

export interface RentalAgreement extends NewRentalAgreement {
  id: string;
  // Virtual (expand=xx)
  expand?: {
    product?: ProductsModel.Product;
    realEstateAsset?: RealEstateAsset;
    rentalUnit?: RentalUnit;
  };
  createdAt: string;
  updatedAt: string;
}

export type RentalAgreementUpdate = RequireField<
  Partial<Omit<RentalAgreement, "product" | "expand" | "createdAt" | "updatedAt">>,
  "id"
>;
export type RentalAgreementUpdateInternal = RequireField<
  Partial<Omit<RentalAgreement, "expand" | "createdAt" | "updatedAt">>,
  "id" | "product"
>;

export enum RentIndexationError {
  InseeError = 'insee_error',
  Unavailable = 'unavailable',
  LimitDateNotReached = 'limit_date_not_reached',
  ForgotDueDate = 'forgot_due_date',
  NoCreated = 'no_created',
}

const FinancialConditionsSchema = new Schema(
  {
    tenseArea: {
      monthlyRentReference: Number,
      monthlyRentReferenceIncreased: Number,
      supplementRent: Number,
      supplementRentJustification: String,
    },
    rentCharge: Number,
    rentGuaranteeDeposit: Number,
    amountOfCharges: Number,
    chargePaymentMethod: {
      type: String,
      enum: Object.values(ChargePaymentMethodEnum),
    },
    paymentTerms: {
      type: String,
      enum: Object.values(PaymentTermsEnum),
    },
    rentPayedFor: {
      type: String,
      enum: Object.values(RentPayedForEnum),
    },
    amountOfTVA: Number,
    indexation: {
      type: {
        type: String,
      },
      index: String,
    },
  },
  { _id: false }
);

const rentalAgreementSchema = new Schema<RentalAgreementDocument>(
  {
    _id: { type: String, default: () => ulid() },
    name: { type: String },
    type: { type: String },
    startAt: { type: String },
    endAt: { type: String },
    product: {
      id: { type: String, index: true },
      realEstateAsset: {
        id: { type: String, index: true },
        rentalUnit: {
          id: { type: String, index: true },
        },
      },
    },
    address: addressSchema,
    options: RentalAgreementOptionsSchema,
    financialConditions: FinancialConditionsSchema,
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentalAgreementDocument) {
        ret.id = ret._id;
        return ret;
      },
    },
  }
);

export type RentalAgreementDocument = RentalAgreement & Document<string>;

// Name of the collection in third argument
export const RentalAgreementModel = model<RentalAgreementDocument>(
  "RentalAgreement",
  rentalAgreementSchema,
  "RentalAgreements"
);

export type RentIndexation = {
  id?: string;
  rentalAgreementId: string;
  currentRent: number;
  currentIrl: string;
  amountOfCharges: number;
  newIrl: string;
  newRent: number;
  revisionDate: IsoDate;
  hasConstruction: boolean;
  status: string;
};

export type RentIndexationDocument = RentIndexation & Document<string>;

const RentIndexationSchema = new Schema<RentIndexationDocument>(
  {
    _id: { type: String, default: () => ulid() },
    rentalAgreementId: {
      type: String,
      required: true,
    },
    currentRent: { type: Number },
    currentIrl: { type: String },
    amountOfCharges: { type: Number },
    newIrl: { type: String },
    newRent: { type: Number },
    revisionDate: { type: String },
    hasConstruction: { type: Boolean },
    status: {
      type: String,
      default: "created",
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RentIndexationDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export const RentIndexationModel = model<RentIndexationDocument>(
  "RentIndexation",
  RentIndexationSchema,
  "RentIndexations"
);

export type Regularization = {
  id?: string;
  rentalAgreementId: string;
  periodStart: string;
  periodEnd: string;
  propertyType: PropertyTypeEnum;
};

export type RegularizationHistoryEntry = {
  id: string;
  productId: string;
  rentalAgreementId: string;
  date: string;
  provisions: string;
  charges: string;
  result: string;
};

export type RegularizationDocument = Regularization & Document<string>;

const RegularizationSchema = new Schema<RegularizationDocument>(
  {
    _id: { type: String, default: () => ulid() },
    productId: {
      type: String,
      required: true,
    },
    rentalAgreementId: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    provisions: {
      type: String,
      required: true,
    },
    charges: { 
      type: String,
      required: true,
    },
    result: { 
      type: String,
      required: true,
    },
  },
  {
    timestamps: true,
    toJSON: {
      versionKey: false,
      virtuals: true,
      transform(doc, ret: RegularizationDocument) {
        ret.id = ret._id;
        delete ret._id;
        return ret;
      },
    },
  }
);

export const RegularizationModel = model<RegularizationDocument>(
  "Regularization",
  RegularizationSchema,
  "Regularizations"
);

// API
export namespace RentalAgreementsService {
  export type CreateIn = NewRentalAgreement;
  export type CreateOut = RentalAgreement;

  export type ListIn = { productId?: string; realEstateAssetId?: string; rentalAgreementActive?: boolean };
  export type ListOut = RentalAgreement[];

  export type GetIn = Pick<RentalAgreement, "id">;
  export type GetOut = RentalAgreement;

  export type UpdateIn = RentalAgreementUpdate;
  export type UpdateOut = RentalAgreement;

  export type DeleteIn = Pick<RentalAgreement, "id">;
  export type DeleteOut = boolean;

  export type ReceiptIn = {
    id: string;
    productId: string;
    bankAccountId: string;
    transactionId: string;
    rentPeriod: string;
    rentAmount: number;
    rentAmountCharge: number;
    rentAmountTVA?: number | undefined;
    rentAmountTotal: number;
    dateOperation: string;
  };
  export type ReceiptOut = Buffer[];

  export type DocumentModelIn = { modelType: DocumentModelType; fileType: DocumentFileType };
  export type DocumentModelOut = Buffer;

  export type RentIndexationIn = Pick<RentalAgreement, "id"> & {
    currentIrl?: string;
    newIrl?: string;
  };
  export type RentIndexationOut = RentIndexation & {
    history: RentIndexation[];
    error?: RentIndexationError;
  };

  export type RevisedRentIndexationIn = Pick<RentalAgreement, "id"> & {
    rentAmount: number;
    hasConstruction: boolean;
    revisionDate: IsoDate;
  };

  export type DeleteRentIndexationIn = Pick<RentalAgreement, "id">;

  export type GetCurrentInseeDataIn = { irlType: RentIndexationTypeParamEnum };

  export type RevisedRentIndexationOut = boolean;
  
  // export type GetPreviewMailIn = Pick<RentalAgreement, "id"> & {
  //   typeOfMail: string;
  // };
  // export type GetPreviewMailOut = string;

  export type CreateRegularizationIn = {
    productId: string;
    rentalAgreementId: string;
    date: string;
    provisions: string;
    charges: string;
    result: string;
  };
  export type CreateRegularizationOut = RegularizationHistoryEntry;

  export type ListRegularizationsIn = { rentalAgreementId: string };
  export type ListRegularizationsOut = RegularizationHistoryEntry[];

  export type DeleteRegularizationIn = Pick<RegularizationHistoryEntry, "id">;
  export type DeleteRegularizationOut = boolean;

}

export namespace RentsService {
  export type SendNotificationRentCallIn = never;
  export type SendNotificationRentCallOut = void;

  export type SendUnpaidRentReminderCallIn = never;
  export type SendUnpaidRentReminderCallOut = void;

  export type SendBankIncorrectRentServicesIn = never;
  export type SendBankIncorrectRentServicesOut = void;

  export type DetectNotSendNotificationRentReceiptIn = {
    afterDate: string;
    exportType?: "json" | "csv";
    sendEmail?: boolean;
    sendRentReceipt?: boolean;
  };
  export type DetectNotSendNotificationRentReceiptOut =
    | StatsRentalAgreementsWithNotSendNotificationRentReceipt[]
    | string;
}
