

























































import { activitiesStore, documentsStore, productsStore } from "@/store";
import {
  computed,
  defineComponent,
  onBeforeMount,
  watch,
} from "@vue/composition-api";
import { ref, Ref } from "@vue/composition-api";

export default defineComponent({
  name: "LogoCard",
  setup() {
    const isLoading: Ref<boolean> = ref(false);

    const fileLogo: Ref<File | null> = ref(null);
    const deleteFileInProgress: Ref<boolean> = ref(false);

    const showSignatureModal: Ref<boolean> = ref(false);
    const productId = computed(() => {
      return productsStore.currentId;
    });
    const activity = computed(() => {
      return activitiesStore.currentActivity;
    });
    const files = {};

    const logoImage = computed(() => {
      if (activity?.value?.logoId) {
        return documentsStore.getDocument(activity.value.logoId);
      }
    });
    //For console error Invalid prop: type check failed for prop "disabled". Expected Boolean, got Object
    const fileInput: Ref<HTMLElement | null> = ref(null); // Référence à l'élément v-file-input

    const fileSignatureRules = (
      fileLogo: File
    ): {
      valid: boolean;
      name: string;
      message: string;
    } => {
      if (
        fileLogo.type !== "image/png" &&
        fileLogo.type !== "image/jpeg" &&
        fileLogo.type !== "image/svg+xml"
      ) {
        return {
          valid: false,
          name: "TYPE ERROR",
          message:
            "Format de fichier non supporté. Seuls les formats JPEG et PNG sont acceptés.",
        };
      }
      if (fileLogo?.size >= 2000000) {
        return {
          valid: false,
          name: "SIZE ERROR",
          message: `Le fichier dépasse la taille maximale autorisée de 2 Mo. Veuillez choisir un fichier plus petit.`,
        };
      }
      return { valid: true, name: "OK", message: "Aucune erreur" };
    };
    async function deleteImage() {
      if (activity.value?.id && activity.value.logoId) {
        await activitiesStore.deleteImage({
          id: activity.value.id,
          logoId: activity.value.logoId,
        });
        delete activity.value.logoId;
      }
    }

    async function validate() {
      isLoading.value = true;

      if (activity.value) {
        if (activity.value.signature?.signatureId) {
          deleteImage();
        }
        files["logo"] = fileLogo;
        await activitiesStore.addImage({
          id: activity.value.id,
          productId: productId.value,
          files,
        });
        fileLogo.value = null;
        isLoading.value = false;
      }
    }
    function cancel() {
      fileLogo.value = null;
    }
    const init = async () => {
      await productsStore.switchProduct({ id: productId.value });
    };

    watch(
      () => [activitiesStore.currentActivity],
      () => init(),
      { deep: true }
    );

    onBeforeMount(async () => await init());

    return {
      activity,
      fileLogo,
      showSignatureModal,
      validate,
      cancel,
      fileSignatureRules,
      logoImage,
      deleteImage,
      deleteFileInProgress,
      fileInput,
      isLoading,
    };
  },
});
