



































































import { defineComponent, computed, PropType } from "@vue/composition-api";
import { extension } from "mime-types";
import { documentsStore } from "@/store";
import { Document } from "@edmp/api";
import { VConfirmDialog } from "@/models";

import Confirm from "@/components/core/modals/Confirm.vue";

import EditableTable, {
  EditableTableRef,
} from "@/components/atom/table/EditableTable.vue";

export default defineComponent({
  name: "SupportingDocumentsPanel",
  components: {
    EditableTable,
    Confirm,
  },
  props: {
    documents: {
      type: Array as PropType<Document[]>,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const itemsToDisplay = computed(() => {
      const documentsItems = documentsStore.documents.length;
      return documentsItems;
    });

    function showDocument(item: Document) {
      window.open(item.href, "_blank");
    }

    const addItem = ({ rowIndex }: { rowIndex: number }) => {
      (context.refs.editableTable as EditableTableRef).updateIsEditing(
        rowIndex,
        false
      );
      context.emit("addDocument");
    };
    const deleteItem = async ({ rowIndex }: { rowIndex: number }) => {
      const documentToDelete = props.documents[rowIndex];

      const openConfirm: boolean = await (
        context.refs.confirmDialog as VConfirmDialog
      ).open(
        `Êtes-vous sûr de vouloir supprimer le document ${documentToDelete.name} ?`
      );

      if (openConfirm) {
        await documentsStore.deleteDocument(documentToDelete.id);
        context.emit("documentDeleted");
      }
    };

    return {
      extension,
      showDocument,
      itemsToDisplay,
      addItem,
      deleteItem,
    };
  },
});
