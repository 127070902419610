



































































import { defineComponent } from "@vue/composition-api";
import { extension } from "mime-types";
import { AccountingPeriod, Document } from "@edmp/api";
import { accountingPeriodsStore, documentsStore } from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

export default defineComponent({
  name: "NewDocumentsTable",
  props: {
    lastDocuments: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const lastDocumentsHeader = [
      { text: "Libellé", value: "name" },
      { text: "Date de génération", value: "createdAt" },
      { text: "Année d'exercice", value: "accountingPeriod" },
      { text: "Type", value: "type", align: "end" },
    ];

    // FIXME : Duplicate code
    const getAccountingPeriodYear = (accountingPeriod: AccountingPeriod) => {
      const accountingPeriods = accountingPeriodsStore.accountingPeriods;
      let accPeriod: AccountingPeriod | undefined;
      if (accountingPeriods && accountingPeriod)
        accPeriod = accountingPeriods.find(
          (elt) => elt.id === accountingPeriod.id
        );
      return accPeriod ? new Date(accPeriod.startAt).getFullYear() : "";
    };

    function showDocument(item: Document) {
      window.open(item.href, "_blank");
    }

    const deleteDocument = async (id) =>
      await documentsStore.deleteDocument(id);

    return {
      ROUTE_NAMES,
      lastDocumentsHeader,
      getAccountingPeriodYear,
      extension,
      showDocument,
      deleteDocument,
    };
  },
});
