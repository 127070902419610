































































































































































































































































































































































































































































































































































































































































































































import {
  accountingPeriodsStore,
  realEstateAssetsStore,
  transactionsStore,
} from "@/store";
import { countDecimals } from "@/utils";
import {
  CategorizationEntry,
  CheckNewAnomaliesType,
  LedgerAccountEnum,
  Suggestion,
  TypeReference,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  watchEffect,
} from "@vue/composition-api";
import { flatMap } from "lodash";
import Anomalies from "../../anomalies/Anomalies.vue";
import DialogRight from "../../DialogRight.vue";
import SupportingDocumentCreate from "../../supportingDocuments/AddSupportingDocumentsModal.vue";
import RealEstateAssetCreate from "../../realEstate/RealEstateForm.vue";
import FixedAssetCreate from "../../fixedAssets/FixedAssetForm.vue";
import TenantCreate from "../create/TenantCreate.vue";
import RealEstateLoanCreate from "../../activities/realEstateLoans/RealEstateLoanModal.vue";
import { TransactionState } from "../transaction/transaction.usable";
import { useCategorization } from "./categorization.usable";

export default defineComponent({
  name: "CategorizationDuplication",
  components: {
    Anomalies,
    DialogRight,
    SupportingDocumentCreate,
    RealEstateAssetCreate,
    TenantCreate,
    FixedAssetCreate,
    RealEstateLoanCreate,
  },
  props: {
    transactionState: {
      type: Object as PropType<TransactionState>,
      required: true,
    },
  },
  setup(props, context) {
    const categorizationUse = computed(() =>
      useCategorization(props.transactionState, context)
    );
    const hasCharges = computed(() => {
      const charges = props.transactionState.lines.find(
        (line) => line.account === LedgerAccountEnum.N708399
      );
      if (charges) {
        return true;
      }
      return false;
    });
    const categories = computed(() =>
      flatMap(transactionsStore.categoriesList)
    );

    const getCategory = (line: CategorizationEntry): Suggestion | undefined =>
      categories.value.find((category) => category.number === line.account);

    const partners = computed(() => categorizationUse.value.partners());
    const fixedAssetsByLine = props.transactionState.lines.map((line) =>
      computed(() => categorizationUse.value.fixedAssets(getCategory(line)))
    );
    const realEstateAssetsByLine = props.transactionState.lines.map((line) =>
      computed(() =>
        categorizationUse.value.realEstateAssets(getCategory(line))
      )
    );
    const rentalAgreementsByLine = props.transactionState.lines.map((line) =>
      computed(() =>
        categorizationUse.value.rentalAgreements(
          line.realEstateAsset,
          getCategory(line)
        )
      )
    );
    const tenantsByLine = props.transactionState.lines.map((line) =>
      computed(() =>
        categorizationUse.value.tenants(line.realEstateAsset, getCategory(line))
      )
    );
    const realEstatesLoansByLine = props.transactionState.lines.map((line) =>
      computed(() =>
        categorizationUse.value.loans(line.realEstateAsset, getCategory(line))
      )
    );
    const supportingDocumentsByLine = props.transactionState.lines.map((line) =>
      computed(() =>
        categorizationUse.value.supportingDocuments(getCategory(line))
      )
    );

    const rentalUnit = computed(() => {
      realEstateAssetsStore.getRentalUnit(
        (
          props.transactionState.lines.find(
            (line) => line.rentalUnit
          ) as CategorizationEntry
        ).rentalUnit as string
      );
    });

    watchEffect(() => {
      props.transactionState.lines.forEach((line) => {
        watch(
          () => line.realEstateAsset,
          () => {
            if (line.realEstateAsset) {
              line.rentalUnit =
                realEstateAssetsStore.getRentalUnitByRealEstateAssetId(
                  line.realEstateAsset
                ).id;
            }
          }
        );
      });
    });

    const realEstateAssetCreate = ref(false);
    const fixedAssetCreate = ref(false);
    const rentalAgreementCreate = ref(false);
    const tenantCreate = ref(false);
    const realEstateLoanCreate = ref(false);
    const supportingDocumentCreate = ref(false);

    const editingFields = ref(
      props.transactionState.lines.map(() => ({
        realEstateAsset: false,
        fixedAsset: false,
        rentalAgreement: false,
        tenant: false,
        partner: false,
        realEstateLoan: false,
        supportingDocument: false,
      }))
    );

    const editField = (index: number, field: string) => {
      editingFields.value[index][field] = true;
    };

    const saveAndClose = (index: number, field: string) => {
      editingFields.value[index][field] = false;
      if (field === "realEstateAsset") {
        if (props.transactionState.lines[index].rentalAgreement) {
          editField(index, "rentalAgreement");
        }
        if (props.transactionState.lines[index].realEstateLoan) {
          editField(index, "realEstateLoan");
        }
      }
    };

    return {
      categorizationUse,
      CheckNewAnomaliesType,
      countDecimals,
      hasCharges,
      editingFields,
      editField,
      saveAndClose,
      partners,
      fixedAssetsByLine,
      realEstateAssetsByLine,
      rentalAgreementsByLine,
      tenantsByLine,
      realEstatesLoansByLine,
      supportingDocumentsByLine,
      TypeReference,
      getCategory,
      realEstateAssetCreate,
      fixedAssetCreate,
      rentalAgreementCreate,
      tenantCreate,
      realEstateLoanCreate,
      supportingDocumentCreate,
      accountingPeriodsStore,
      rentalUnit,
    };
  },
});
