

































































import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import { defineComponent, ref } from "@vue/composition-api";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import TitleNew from "../title/TitleNew.vue";
import UploadFlowDialog from "@/components/atom/UploadFlowDialog.vue";

export default defineComponent({
  name: "DocumentsHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    displayHelper: {
      type: Boolean,
      default: false,
    },
    displayFilter: {
      type: Boolean,
      default: false,
    },
    displayAccountingPeriod: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AccountingPeriodChoice,
    TitleNew,
    UploadFlowDialog,
  },
  setup() {
    const openUploadFlowDialog = ref(false);

    return {
      openUploadFlowDialog,
      openArticleDocumentsSaving: () =>
        useCrisp().openArticle(ArticleEnum.DOCUMENT_SAVING),
    };
  },
});
