var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modelsLibrary-view"},[_c('PageHeader',[_vm._v(" Mes modèles ")]),_c('div',{staticClass:"modelsLibrary-header"},[_c('router-link',{staticClass:"documents-back d-flex align-center",attrs:{"to":{
        name: _vm.ROUTE_NAMES.Documents,
      }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),_vm._v(" Mon espace documentaire ")],1)],1),_c('HelpingMessage',{staticClass:"mt-3 mb-5",attrs:{"title":"En savoir plus"}},[_c('p',{staticClass:"mb-4"},[_vm._v(" Bienvenue dans votre bibliothèque de modèles, une ressource essentielle pour optimiser la gestion de vos biens immobiliers. Nos modèles de documents, constamment mis à jour selon les dernières réglementations, vous assurent une conformité légale sans faille. Gagnez du temps grâce à nos modèles pré-remplis et personnalisables en quelques clics. Notre bibliothèque contient des modèles pour la gestion locative, les préavis d’indexation et de régularisation, les procédures de recouvrement, les garanties locatives, et les contrats de location. Téléchargeables en formats Word et PDF, ces modèles vous permettent de vous concentrer davantage sur l’optimisation de vos investissements immobiliers. ")])]),_vm._l((_vm.sections),function(section){return _c('v-expansion-panels',{key:section,staticClass:"documentsPanel mb-4"},[_c('v-expansion-panel',{staticClass:"pa-sm-2 pa-md-4"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',[_vm._v(" $expand ")])]},proxy:true}],null,true)},[_c('h3',{staticClass:"documents-section-title"},[_vm._v(_vm._s(section.name))])]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"documents-table",attrs:{"hide-default-footer":"","headers":section.name === 'Modèles de gestion locative' ||
            section.name === 'Modèles de contrats de location'
              ? _vm.headers
              : _vm.headersWithoutOptions,"items":section.models,"items-per-page":10,"no-data-text":"Aucun document"},scopedSlots:_vm._u([{key:"header.format",fn:function(ref){
              var header = ref.header;
return [_c('div',{staticClass:"header-format"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.options",fn:function(ref){
              var item = ref.item;
return [(item.hasOptions)?_c('div',{staticClass:"checkbox"},[_c('v-checkbox',{attrs:{"label":"TVA"},model:{value:(item.withVat),callback:function ($$v) {_vm.$set(item, "withVat", $$v)},expression:"item.withVat"}}),_c('v-checkbox',{attrs:{"label":"APL"},model:{value:(item.withApl),callback:function ($$v) {_vm.$set(item, "withApl", $$v)},expression:"item.withApl"}})],1):_vm._e(),_c('div',{staticClass:"amendment-options"},[(item.list)?_c('div',{staticClass:"select-lease-adment"},[_c('v-select',{attrs:{"items":item.list,"item-text":"text","item-value":"value","outlined":"","hide-details":""},model:{value:(item.modelType),callback:function ($$v) {_vm.$set(item, "modelType", $$v)},expression:"item.modelType"}})],1):_vm._e(),(item.list)?_c('div',{staticClass:"radio-lines"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(item.lessor),callback:function ($$v) {_vm.$set(item, "lessor", $$v)},expression:"item.lessor"}},[_c('v-radio',{attrs:{"label":"Bailleur Société","value":"company"}}),_c('v-radio',{attrs:{"label":"Bailleur Particulier","value":"nat_pers"}})],1),_c('v-radio-group',{attrs:{"row":""},model:{value:(item.tenant),callback:function ($$v) {_vm.$set(item, "tenant", $$v)},expression:"item.tenant"}},[_c('v-radio',{attrs:{"label":"Locataire Société","value":"company"}}),_c('v-radio',{attrs:{"label":"Locataire Particulier","value":"nat_pers"}})],1)],1):_vm._e()]),(item.type)?_c('div',{staticClass:"radio-lines"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}},[_c('v-radio',{attrs:{"label":"Studio - T1","value":"studio"}}),_c('v-radio',{attrs:{"label":"T2 - T3","value":"t2"}}),_c('v-radio',{attrs:{"label":"T4 - T5","value":"t4"}}),_c('v-radio',{attrs:{"label":"Maison","value":"house"}}),_c('v-radio',{attrs:{"label":"Commercial","value":"commercial"}})],1)],1):_vm._e()]}},{key:"item.format",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',{staticClass:"document-format"},_vm._l((Object.values(_vm.DocumentFileType)),function(fileType){return _c('v-btn',_vm._g(_vm._b({key:fileType,staticClass:"document-type pa-3 ml-2",attrs:{"depressed":"","color":"#757575"},on:{"click":function($event){return _vm.downloadModel(item, fileType)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(fileType)+" ")])}),1)]}}],null,true)},[_c('span',[_vm._v("Voir le document")])])]}}],null,true)})],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }