


























































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AccountingBalanceSheet,
  CategorizationEntry,
  getAmortisationAccountByAccount,
  getGlobalAccountFromAccountWithReferenceCounter,
  getMoment,
  getTotalPassiveAndActive,
  isAmortisationAccount,
  LedgerAccountEnum,
  Suggestion,
  TaskStatus,
  TaxRegime,
  TaskCode,
  TaskGroupCode,
  getReferredIdByTypeWithReferences,
  TaskTypeReference,
  Direction,
} from "@edmp/api";
import { cloneDeep, flatMap } from "lodash";

import {
  accountingBalanceSheetsStore,
  accountingPeriodsStore,
  coreStore,
  documentsStore,
  tasksStore,
  transactionsStore,
} from "@/store";
import { ArticleEnum, useCrisp } from "@/composables/crisp.usable";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import { AccountingBalanceSheetLocal, VForm } from "@/models";

import TitleNew from "@/components/core/title/TitleNew.vue";
import PageHeader from "@/components/atom/PageHeader.vue";
import { HelpingMessage } from "@/components/atom/helping";
import DocumentCard from "@/components/core/documents/DocumentCard.vue";
import { CustomLabelButton, SubmitButton } from "@/components/atom/button";
import AccountingBalanceSheetTable from "./AccountingBalanceSheetTable.vue";
import AccountingBalanceSheetRecoveryData from "./AccountingBalanceSheetRecoveryData.vue";
import TaskGroupCard from "../tasks/taskGroup/TaskGroupCard.vue";

export default defineComponent({
  name: "AccountingBalanceSheetPage",
  components: {
    PageHeader,
    TaskGroupCard,
    HelpingMessage,
    DocumentCard,
    CustomLabelButton,
    SubmitButton,
    AccountingBalanceSheetRecoveryData,
    AccountingBalanceSheetTable,
    TitleNew,
  },
  setup(props, context) {
    const openDialog = ref(true);
    const taxRegime = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );
    const isIR = computed(() => accountingPeriodsStore.isIR);
    const isIS = computed(() => accountingPeriodsStore.isIS);
    const isLMNP = computed(() => accountingPeriodsStore.isLMNP);
    const currentAccountingPeriod = computed(
      () => accountingPeriodsStore.currentAccountingPeriod
    );
    const balanceSheetTask = computed(() =>
      tasksStore.completedTasks?.find(
        (task) =>
          task.code === TaskCode.ValidateAndObtainBalanceSheet &&
          getReferredIdByTypeWithReferences(
            task.references,
            TaskTypeReference.accountingPeriod
          ) === accountingPeriodsStore.currentId
      )
    );
    const showExport = computed(
      () =>
        router.currentRoute.name === ROUTE_NAMES.AccountingBalanceSheet &&
        (coreStore.isNotProduction || !!balanceSheetTask.value)
    );

    const pageTitle = computed(() =>
      context.root.$route.name === ROUTE_NAMES.AccountingBalanceSheetRecovery
        ? `Reprise de mon bilan ${accountingPeriodYear.value}`
        : `Ma comptabilité - Mon bilan ${accountingPeriodYear.value}`
    );

    const documents = computed(() =>
      documentsStore.documents.filter(
        (document) =>
          document.tags?.includes("accountingBalanceSheet") &&
          document.createdBy === "ownily" &&
          accountingBalanceSheet.value?.type === "closure" &&
          document.product?.accountingPeriod?.id ===
            accountingBalanceSheet.value.accountingPeriodId
      )
    );

    const taskGroup = computed(() => {
      return tasksStore.startWellTaskGroup.find((taskGroup) =>
        [
          TaskGroupCode.StartWellAccounting,
          TaskGroupCode.StartWellAccountingLMNP,
        ].includes(taskGroup.groupCode)
      );
    });

    const navigateToStartWellTasks = () =>
      router.push({ name: ROUTE_NAMES.Tasks });

    const taskGroupPending = computed(() => {
      if (isIS.value) {
        return !!tasksStore.startWellTaskGroup
          .find(
            (taskGroup) =>
              taskGroup.groupCode === TaskGroupCode.StartWellAccounting
          )
          ?.tasksLocal.find(
            (taskLocal) =>
              (taskLocal.code === TaskCode.PartnerCreate ||
                taskLocal.code === TaskCode.AssetUpdate ||
                taskLocal.code === TaskCode.BankAccountLink ||
                taskLocal.code === TaskCode.AmortisationUpdate) &&
              taskLocal.status === TaskStatus.PENDING
          );
      } else if (isLMNP.value) {
        return false;
        // return !!tasksStore.startWellTaskGroup
        //   .find(
        //     (taskGroup) =>
        //       taskGroup.groupCode === TaskGroupCode.StartWellAccountingLMNP ||
        //       taskGroup.groupCode === TaskGroupCode.StartWellLMNP
        //   )
        //   ?.tasksLocal.find(
        //     (taskLocal) =>
        //       (taskLocal.code === TaskCode.DeferrableAmortisationsAdd ||
        //         taskLocal.code === TaskCode.DeferrableDeficitsAdd ||
        //         taskLocal.code === TaskCode.AssetUpdate ||
        //         taskLocal.code === TaskCode.AmortisationUpdate ||
        //         taskLocal.code === TaskCode.LoanAdd) &&
        //       taskLocal.status === TaskStatus.PENDING
        //   );
      } else {
        return false;
      }
    });
    const isReadOnlyPage = computed(() => {
      if (isIS && balanceSheet.value?.type === "closure") {
        return !!balanceSheetTask.value;
      } else {
        return (
          taskGroupPending.value ||
          (balanceSheet.value?.accountingPeriodId &&
          balanceSheet.value.type === "recovery"
            ? !accountingPeriodsStore.accountingPeriods.some(
                (accountingPeriod) =>
                  accountingPeriod.id === balanceSheet.value?.accountingPeriodId
              )
            : !!accountingPeriodFirst.value.closed) ||
          !!tasksStore.completedTasks.some(
            (task) =>
              (task.code === TaskCode.FillCerfa2033 ||
                task.code === TaskCode.FillCerfa2065 ||
                task.code === TaskCode.FillFec) &&
              getReferredIdByTypeWithReferences(
                task.references,
                TaskTypeReference.accountingPeriod
              ) === accountingPeriodsStore.currentId
          )
        );
      }
    });
    const isReadOnlyPageValidated = computed(() => {
      if (isIS && balanceSheet.value?.type === "closure") {
        return (
          accountingPeriodsStore.currentAccountingPeriod?.closed ||
          balanceSheet.value.isValidated
        );
      } else {
        return !!taskGroup.value?.tasksLocal.some(
          (task) =>
            (task.code === TaskCode.BalanceSheetRecovery &&
              task.status === TaskStatus.COMPLETED) ||
            (task.code === TaskCode.InjectBalance &&
              task.status === TaskStatus.COMPLETED)
        ) ||
          (balanceSheet.value?.accountingPeriodId &&
            balanceSheet.value.type === "recovery")
          ? !accountingPeriodsStore.accountingPeriods.some(
              (accountingPeriod) =>
                accountingPeriod.id === balanceSheet.value?.accountingPeriodId
            )
          : !!accountingPeriodFirst.value.closed;
      }
    });
    const isReadOnlyRecoveryData = computed(() =>
      balanceSheet.value?.accountingPeriodId &&
      balanceSheet.value.type === "recovery"
        ? accountingPeriodsStore.accountingPeriods.some(
            (accountingPeriod) =>
              accountingPeriod.id === balanceSheet.value?.accountingPeriodId
          )
        : isReadOnlyPage.value || isReadOnlyPageValidated.value
    );
    const isReadOnlyLines = computed(
      () => isReadOnlyPage.value || !balanceSheet.value?.id
    );

    const categories = computed(() =>
      flatMap(transactionsStore.categoriesList)
    );

    const accountingPeriodFirst = computed(
      () =>
        cloneDeep(accountingPeriodsStore.accountingPeriods).sort((acc1, acc2) =>
          getMoment(acc1.endAt).diff(acc2.endAt)
        )[0]
    );
    const accountingPeriodYear = computed(() =>
      accountingBalanceSheet.value?.type === "recovery"
        ? getMoment(accountingBalanceSheet.value?.endAt).format("YYYY")
        : getMoment(
            accountingPeriodsStore.currentAccountingPeriod?.endAt
          ).format("YYYY")
    );

    // Accounting balance sheet recovery
    const accountingBalanceSheet = computed(() =>
      context.root.$route.name === ROUTE_NAMES.AccountingBalanceSheet
        ? accountingBalanceSheetsStore
            .getAccountingBalanceSheetByType("closure")
            .find(
              (accountingBalanceSheet) =>
                accountingBalanceSheet.type === "closure" &&
                accountingBalanceSheet.accountingPeriodId ===
                  accountingPeriodsStore.currentId
            )
        : accountingBalanceSheetsStore
            .getAccountingBalanceSheetByType("recovery")
            .find(
              (accountingBalanceSheet) =>
                accountingBalanceSheet.type === "recovery" &&
                getMoment(accountingBalanceSheet.endAt).year() ===
                  getMoment(currentAccountingPeriod.value?.endAt).year() - 1
            )
    );
    const balanceSheet = ref(cloneDeep(accountingBalanceSheet.value));
    watch(accountingBalanceSheet, () => {
      if (accountingBalanceSheet.value) {
        balanceSheet.value = cloneDeep(accountingBalanceSheet.value);
      }
    });

    const itemsKeyValueSuffix: {
      [key in keyof Pick<CategorizationEntry, "amount">]: string;
    } = {
      amount: "€",
    };

    const totalPassiveAndActive = computed(() =>
      balanceSheet.value
        ? getTotalPassiveAndActive(
            cloneDeep(balanceSheet.value).lines,
            categories.value
          )
        : undefined
    );
    const totalActiveText = computed(() => {
      return `${
        totalPassiveAndActive.value
          ? totalPassiveAndActive.value.totalActive
          : 0
      }${itemsKeyValueSuffix.amount}`;
    });
    const totalPassiveText = computed(() => {
      return `${
        totalPassiveAndActive.value
          ? `${totalPassiveAndActive.value.totalPassive}`
          : 0
      }${itemsKeyValueSuffix.amount}`;
    });

    // Table
    const processLinesByAccountBalanceTypeAndTransformToLocal = (
      lines: AccountingBalanceSheet["lines"],
      accountBalanceType: Suggestion["accountBalanceType"]
    ): AccountingBalanceSheetLocal["lines"] =>
      taxRegime.value === TaxRegime.IR_2072
        ? lines.filter(
            (line) =>
              categories.value.find(
                (categorie) =>
                  (line.reference
                    ? getGlobalAccountFromAccountWithReferenceCounter(
                        line.account,
                        line.reference
                      )
                    : line.account) === categorie.number
              )?.accountBalanceType === accountBalanceType
          )
        : lines
            .filter((line) => {
              const account = line.reference
                ? getGlobalAccountFromAccountWithReferenceCounter(
                    line.account,
                    line.reference
                  )
                : line.account;
              if (
                account === LedgerAccountEnum.N512000 &&
                line.direction === Direction.debit
              ) {
                return accountBalanceType === "debts";
              }
              return (
                categories.value.find((category) => account === category.number)
                  ?.accountBalanceType === accountBalanceType
              );
            })
            .map((line, index, lines) => {
              const lineToUpdate: AccountingBalanceSheetLocal["lines"][number] =
                line;
              const amortisationAccount = getAmortisationAccountByAccount(
                (line.reference
                  ? getGlobalAccountFromAccountWithReferenceCounter(
                      line.account,
                      line.reference
                    )
                  : line.account) as LedgerAccountEnum
              );
              if (amortisationAccount) {
                const amortisationLineIndex = lines.findIndex(
                  (lineFind) =>
                    (lineFind.reference
                      ? getGlobalAccountFromAccountWithReferenceCounter(
                          lineFind.account,
                          lineFind.reference
                        )
                      : lineFind.account) === amortisationAccount &&
                    lineFind.reference?.type === line.reference?.type &&
                    lineFind.reference?.referredId ===
                      line.reference?.referredId
                );
                if (amortisationLineIndex !== -1) {
                  lineToUpdate.amountAmortisation =
                    lines[amortisationLineIndex].amount;
                }
              }
              return lineToUpdate;
            })
            .filter(
              (line) =>
                line !== undefined &&
                !isAmortisationAccount(line.account) &&
                (line.reference
                  ? getGlobalAccountFromAccountWithReferenceCounter(
                      line.account,
                      line.reference
                    )
                  : line.account) !== LedgerAccountEnum.N164100 &&
                (line.reference
                  ? getGlobalAccountFromAccountWithReferenceCounter(
                      line.account,
                      line.reference
                    )
                  : line.account) !== LedgerAccountEnum.N512100
            );

    const balanceSheetFixedAssetsLines = computed(() =>
      balanceSheet.value
        ? Object.assign(cloneDeep(balanceSheet.value), {
            lines: processLinesByAccountBalanceTypeAndTransformToLocal(
              cloneDeep(balanceSheet.value).lines,
              "fixed_assets"
            ),
          })
        : undefined
    );
    const balanceSheetCurrentAssetsLines = computed(() =>
      balanceSheet.value
        ? Object.assign(cloneDeep(balanceSheet.value), {
            lines: processLinesByAccountBalanceTypeAndTransformToLocal(
              cloneDeep(balanceSheet.value).lines,
              "current_assets"
            ),
          })
        : undefined
    );

    const balanceSheetShareholdersEquityLines = computed(() =>
      balanceSheet.value
        ? Object.assign(cloneDeep(balanceSheet.value), {
            lines: processLinesByAccountBalanceTypeAndTransformToLocal(
              cloneDeep(balanceSheet.value).lines,
              "shareholders_equity"
            ),
          })
        : undefined
    );
    const balanceSheetDebtsLines = computed(() =>
      balanceSheet.value
        ? Object.assign(cloneDeep(balanceSheet.value), {
            lines: processLinesByAccountBalanceTypeAndTransformToLocal(
              cloneDeep(balanceSheet.value).lines,
              "debts"
            ),
          })
        : undefined
    );

    // Rules
    const rules = computed(() => ({
      isEqualTotalActifTotalPasif:
        totalPassiveAndActive.value?.totalActive ===
        totalPassiveAndActive.value?.totalPassive,
    }));

    // Validate
    const isLoadingValidate = ref(false);
    const validate = async () => {
      if (
        balanceSheet.value &&
        (context.refs.form as VForm).validate() &&
        rules.value.isEqualTotalActifTotalPasif
      ) {
        isLoadingValidate.value = true;
        try {
          await accountingBalanceSheetsStore.validateAccountingBalanceSheet({
            accountingBalanceSheetId: balanceSheet.value.id,
            isValidated: !balanceSheet.value.isValidated,
          });
        } catch (error) {
          console.log(error);
        } finally {
          isLoadingValidate.value = false;
        }
      }
    };

    // Methods
    const getReporting = async () => {
      if (balanceSheet.value) {
        await accountingBalanceSheetsStore.reportAccountingBalanceSheets(
          balanceSheet.value.id
        );
      }
    };

    const goToRecoveryBalanceSheet = async () => {
      openDialog.value = false;
      const nextAccountingPeriod =
        accountingPeriodsStore.accountingPeriods.find(
          (accountingPeriod) =>
            getMoment(accountingPeriod.endAt).year() ===
            getMoment(
              accountingPeriodsStore.currentAccountingPeriod?.endAt
            ).year() +
              1
        );
      if (nextAccountingPeriod) {
        await accountingPeriodsStore.switchAccountingPeriod(
          nextAccountingPeriod.id
        );
      }
      context.emit("change");
      context.root.$router.push({
        name: ROUTE_NAMES.AccountingBalanceSheetRecovery,
      });
    };

    // Init
    onBeforeMount(async () => {
      await transactionsStore.updateCategoriesList();
      await accountingBalanceSheetsStore.fetchAccountingBalanceSheets();
      documentsStore.fetchDocuments();
    });

    return {
      pageTitle,
      ROUTE_NAMES,
      TaxRegime,
      isIR,
      isIS,
      isLMNP,
      documents,
      taskGroup,
      taskGroupPending,
      TaskStatus,
      navigateToStartWellTasks,
      isReadOnlyPage,
      isReadOnlyPageValidated,
      isReadOnlyLines,
      isReadOnlyRecoveryData,
      categories,
      accountingPeriodYear,
      balanceSheet,
      getReporting,
      // Table
      totalActiveText,
      totalPassiveText,
      rules,
      balanceSheetFixedAssetsLines,
      balanceSheetCurrentAssetsLines,
      balanceSheetShareholdersEquityLines,
      balanceSheetDebtsLines,
      // Validate
      isLoadingValidate,
      validate,
      //Help
      openArticleAccountingBalanceSheetRecovery: () =>
        useCrisp().openArticle(ArticleEnum.ACCOUNTING_BALANCE_SHEET_RECOVERY),
      ArticleEnum,
      showExport,
      openDialog,
      currentAccountingPeriod,
      goToRecoveryBalanceSheet,
    };
  },
});
