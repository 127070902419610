























































































































































































































































import { VForm } from "@/models";
import { productsStore, coreStore, activitiesStore } from "@/store";
import { FeedbackTypeEnum } from "@/store/modules/Core.store";
import { debounce, onlyNumber } from "@/utils";
import {
  ActivitiesModel,
  ProductsModel,
  ProductsService,
  RequireField,
} from "@edmp/api";
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  name: "myBusinessOperatorInfo",
  props: {
    loading: { type: Boolean, default: false },
    actions: { type: String as PropType<"create" | "update"> },
  },

  setup(props, context) {
    const isLoading: Ref<boolean> = ref(props.loading);
    // Infos générales
    const isEditing: Ref<boolean> = ref(false);

    const rules = ref({
      firstName: () =>
        !!(formData.value as unknown as { firstName: string | undefined })
          .firstName || "Renseignez votre prénom",
      lastName: () =>
        !!(formData.value as unknown as { lastName: string | undefined })
          .lastName || "Renseignez votre nom",
      street: () => !!formData.value.address?.street || "Renseignez votre rue",
      city: () => !!formData.value.address?.city || "Renseignez votre ville",
      zip: () =>
        (!!formData.value.address?.zip &&
          onlyNumber(formData.value.address?.zip)) ||
        "Le format de votre code postal est incorrect",
      phone: () =>
        (!!formData.value.phone &&
          onlyNumber(formData.value.phone) &&
          formData.value.phone.length === 10) ||
        "Le format de votre numéro de téléphone est incorrect",
    });

    const currentActivity: ComputedRef<
      | ActivitiesModel.Activity<
          ActivitiesModel.ActivityTypes.OPERATOR,
          ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
        >
      | undefined
    > = computed(
      () =>
        activitiesStore.currentActivity as
          | ActivitiesModel.Activity<
              ActivitiesModel.ActivityTypes.OPERATOR,
              ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
            >
          | undefined
    );

    const formData: Ref<
      RequireField<
        Omit<
          | ActivitiesModel.ActivityCreate<
              ActivitiesModel.ActivityTypes.OPERATOR,
              ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
            >
          | ActivitiesModel.ActivityUpdate<
              ActivitiesModel.ActivityTypes.OPERATOR,
              ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON
            >,
          "id" | "productId"
        >,
        "type"
      >
    > = ref({
      type:
        currentActivity.value?.type ?? ActivitiesModel.ActivityTypes.OPERATOR,
      operatorType:
        currentActivity.value?.operatorType ??
        ActivitiesModel.ActivityOperatorTypes.NATURAL_PERSON,
      firstName: currentActivity.value?.firstName,
      lastName: currentActivity.value?.lastName,
      address: {
        street: currentActivity.value?.address?.street,
        zip: currentActivity.value?.address?.zip,
        city: currentActivity.value?.address?.city,
      },
      phone: currentActivity.value?.phone,
      birthDate: currentActivity.value?.birthDate,
      indieChecked: currentActivity.value?.indieChecked ?? false,
      hasCga: false,
    });

    function updateField(value: string, path: string) {
      const pList = path.split(".");
      const key = pList.pop();
      const pointer = pList.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue] === undefined)
          accumulator[currentValue] = {};
        return accumulator[currentValue];
      }, formData.value);
      pointer[key as string] = value;
    }

    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city} - ${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsCityList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode} - ${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field) => {
      if (formData.value.address) {
        if (event.type === "keyup")
          formData.value.address[field] = event.target.value;
        else {
          if (field === "street") {
            formData.value.address.street = event.value.name;
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          } else if (field === "city") {
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          } else if (field === "zip") {
            formData.value.address.city = event.value.city;
            formData.value.address.zip = event.value.postcode;
          }
        }
      }
    };

    async function updateOperator() {
      isLoading.value = true;

      // Process
      if (formData.value && (context.refs.form as VForm).validate()) {
        try {
          context.emit("validate", true);
          if (currentActivity.value?.id) {
            await activitiesStore.updateActivity({
              id: currentActivity.value.id,
              ...formData.value,
            });
          } else {
            await productsStore.createProduct({
              type: ProductsModel.ProductTypes.LMNP,
              name: "",
              activity: {
                ...formData.value,
              } as ProductsService.CreateIn<ProductsModel.ProductTypes.LMNP>["activity"],
              dedicatedBankAccount: false,
              accountingPeriods: [],
            });
          }
          coreStore.displayFeedback({
            message:
              "La mise à jour de vos coordonnées a bien été prise en compte",
          });
          isEditing.value = false;
        } catch (err) {
          coreStore.displayFeedback({
            type: FeedbackTypeEnum.ERROR,
            message:
              "Une erreur est survenue lors de la mise à jour de vos coordonnées",
          });
          isLoading.value = false;
        }
      }
      isLoading.value = false;
    }

    function cancelUpdate() {
      formData.value = Object.assign({
        ...JSON.parse(JSON.stringify(currentActivity.value)),
      });
      isEditing.value = false;
    }

    const productId = computed(() => {
      return productsStore.currentId;
    });

    /**
     * Init
     */
    async function init() {
      isLoading.value = true;
      if (!props.actions || props.actions === "update") {
        if (productId.value) {
          await productsStore.switchProduct({ id: productId.value });
        }
      } else {
        if (!currentActivity.value?.id) {
          isEditing.value = true;
        } else {
          isEditing.value = false;
        }
      }

      isLoading.value = false;
    }

    watch(
      () => [
        activitiesStore.currentActivity,
        productsStore.currentProduct,
        props.actions,
      ],
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => init());

    return {
      isLoading,
      isEditing,
      onlyNumber,
      rules,
      cancelUpdate,
      updateOperator,
      currentActivity,
      formData,
      updateField,
      searchAddress,
      updateAddress,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
    };
  },
});
