























































import {
  defineComponent,
  computed,
  Ref,
  ref,
  ComputedRef,
} from "@vue/composition-api";
import DocumentsPanel from "../../../documents/DocumentsPanel.vue";
import EventsGeneralAssemblyAddDocumentsModal from "../modals/EventsGeneralAssemblyAddDocumentsModal.vue";
import { accountingPeriodsStore, documentsStore } from "@/store";
import { GeneralAssemblyEvent } from "@edmp/api";
import moment from "moment";

export default defineComponent({
  name: "EventsGeneralAssemblyDocuments",
  props: {
    event: { type: Object as () => GeneralAssemblyEvent, required: true },
  },
  components: {
    DocumentsPanel,
    EventsGeneralAssemblyAddDocumentsModal,
  },
  setup(props) {
    const headers = [
      { text: "Libellé", value: "name" },
      { text: "Bien", value: "realEstateAsset" },
      { text: "Date de génération", value: "createdAt" },
      { text: "Année d'exercice", value: "accountingPeriod" },
      { text: "Format", value: "type" },
      { text: "Supprimer", value: "delete", sortable: false },
    ];

    const showDocumentsModal: Ref<boolean> = ref(false);

    const sectionName: ComputedRef<string> = computed(() => {
      const date = props.event.startAt
        ? moment(props.event.startAt).format("DD/MM/YYYY")
        : "";

      let name = "";
      if (props.event.type == "ordinary") {
        if (props.event.pattern == "yearEnd")
          name = "Approbation des comptes annuelle";
        else name = "Assemblée Générale Ordinaire";
      } else {
        name = "Assemblée Générale Extraordinaire";
      }

      return `Mes documents - ${name} du ${date}`;
    });

    function getDocuments() {
      documentsStore.fetchDocuments();
    }

    return {
      headers,
      sectionName,
      getDocuments,
      generaleAssemblyDocs: computed(() =>
        documentsStore.documents.filter(
          (doc) =>
            doc.product?.accountingPeriod?.id ===
              accountingPeriodsStore.currentId &&
            doc.tags &&
            doc.tags.includes("event") &&
            doc.generaleAssembly?.id == props.event.id
        )
      ),
      showDocumentsModal,
    };
  },
});
