var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document-card pa-3",on:{"click":function($event){return _vm.showDocument()}}},[_c('div',{staticClass:"document-header"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"document-name"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.document.name)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.document.name))])]),_c('span',[_vm._v(" "+_vm._s(_vm.$d( new Date(_vm.document.updatedAt), "day-month-year-long-hours-minutes", "fr-FR" ))+" ")])],1),_c('div',{staticClass:"document-details position-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"document-type pa-3",attrs:{"depressed":"","color":"#757575"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(_vm.document.type))+" ")])]}}])},[_c('span',[_vm._v("Voir le document")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteDocument()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v("Supprimer")])])],1),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }