var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categorizationDuplication"},[_c('h4',{staticClass:"categorization-sumup-title mb-3"},[_vm._v("Récapitulatif")]),_c('div',[_vm._l((_vm.transactionState.lines),function(line,index){return _c('div',{key:(index + "." + (line.accountName)),staticClass:"categorization-line"},[_c('div',{staticClass:"categorization-sumup py-3 pl-1"},[_c('span',[_vm._v(" "+_vm._s(line.accountName)+" "),_c('span',{staticClass:"categorization-attribut d-flex flex-row flex-wrap mb-0"},[(
                !_vm.editingFields[index].realEstateAsset && line.realEstateAsset
              )?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'realEstateAsset')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-outline")]),(line.realEstateAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", line.realEstateAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateAsset", line.realEstateAsset )))])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].realEstateAsset ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.realEstateAsset,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.realEstateAsset,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.realEstateAssetsByLine[index].value,"item-text":"name","item-value":"id","label":"Bien","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'realEstateAsset')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.realEstateAssetCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un bien ")],1)]},proxy:true}],null,true),model:{value:(line.realEstateAsset),callback:function ($$v) {_vm.$set(line, "realEstateAsset", $$v)},expression:"line.realEstateAsset"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.realEstateAssetCreate}},[(_vm.realEstateAssetCreate)?_c('RealEstateAssetCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.realEstateAssetCreate = false},"validate":function (realEstateAssetId) {
                    line.realEstateAsset = realEstateAssetId;
                    _vm.realEstateAssetCreate = false;
                  }}}):_vm._e()],1),(!_vm.editingFields[index].fixedAsset && line.fixedAsset)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'fixedAsset')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-clock-outline")]),(line.fixedAsset)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", line.fixedAsset ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "fixedAsset", line.fixedAsset )))])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].fixedAsset ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.fixedAsset,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.fixedAsset,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.fixedAssetsByLine[index].value,"item-text":"name","item-value":"id","label":"Immobilisation","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'fixedAsset')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.fixedAssetCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter une immobilisation ")],1)]},proxy:true}],null,true),model:{value:(line.fixedAsset),callback:function ($$v) {_vm.$set(line, "fixedAsset", $$v)},expression:"line.fixedAsset"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.fixedAssetCreate}},[(_vm.fixedAssetCreate)?_c('FixedAssetCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.fixedAssetCreate = false},"validate":function (fixedAssetId) {
                    line.fixedAsset = fixedAssetId;
                    _vm.fixedAssetCreate = false;
                  }}}):_vm._e()],1),(
                !_vm.editingFields[index].rentalAgreement && line.rentalAgreement
              )?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'rentalAgreement')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-key-outline")]),(line.rentalAgreement)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", line.rentalAgreement ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "rentalAgreement", line.rentalAgreement ))+" ")])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].rentalAgreement ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.rentalAgreement,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.rentalAgreement,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.rentalAgreementsByLine[index].value,"item-text":"name","item-value":"id","label":"Locataire","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'rentalAgreement')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.rentalAgreementCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter une location ")],1)]},proxy:true}],null,true),model:{value:(line.rentalAgreement),callback:function ($$v) {_vm.$set(line, "rentalAgreement", $$v)},expression:"line.rentalAgreement"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.rentalAgreementCreate}},[(_vm.rentalAgreementCreate && line.rentalUnit)?_c('TenantCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"realEstateAsset":{ id: line.realEstateAsset },"rentalUnitId":line.rentalUnit,"editable":"true","hidePagination":"true"},on:{"close":function($event){_vm.rentalAgreementCreate = false},"validate":function (rentalAgreementId) {
                    line.rentalAgreement = rentalAgreementId;
                    _vm.rentalAgreementCreate = false;
                  }}}):_vm._e()],1),(!_vm.editingFields[index].tenant && line.tenant)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'tenant')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-key-outline")]),(line.tenant)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("tenant", line.tenant))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("tenant", line.tenant))+" ")])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].tenant ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.tenant,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.tenant,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.tenantsByLine[index].value,"item-text":"name","item-value":"id","label":"Locataire","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'tenant')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.tenantCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un locataire ")],1)]},proxy:true}],null,true),model:{value:(line.tenant),callback:function ($$v) {_vm.$set(line, "tenant", $$v)},expression:"line.tenant"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.tenantCreate}},[(_vm.tenantCreate && line.rentalUnit)?_c('TenantCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"realEstateAsset":{ id: line.realEstateAsset },"rentalUnitId":line.rentalUnit,"editable":"true","hidePagination":"true"},on:{"close":function($event){_vm.tenantCreate = false},"validate":function (tenantId) {
                    line.tenant = tenantId;
                    _vm.tenantCreate = false;
                  }}}):_vm._e()],1),(!_vm.editingFields[index].partner && line.partner)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'partner')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-account-outline")]),(line.partner)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("partner", line.partner))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute("partner", line.partner))+" ")])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].partner ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.partner,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.partner,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.partners,"item-text":"name","item-value":"id","label":"Associé","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'partner')}},model:{value:(line.partner),callback:function ($$v) {_vm.$set(line, "partner", $$v)},expression:"line.partner"}}):_vm._e(),(
                !_vm.editingFields[index].realEstateLoan && line.realEstateLoan
              )?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'realEstateLoan')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-home-percent-outline")]),(line.realEstateLoan)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", line.realEstateLoan ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categorizationUse.getAttribute( "realEstateLoan", line.realEstateLoan )))])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].realEstateLoan ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.realEstateLoan,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.realEstateLoan,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.realEstatesLoansByLine[index].value,"item-text":"name","item-value":"id","label":"Emprunt","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'realEstateLoan')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"id":"add-credit-transaction","text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.realEstateLoanCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un emprunt ")],1)]},proxy:true}],null,true),model:{value:(line.realEstateLoan),callback:function ($$v) {_vm.$set(line, "realEstateLoan", $$v)},expression:"line.realEstateLoan"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.realEstateLoanCreate}},[(_vm.realEstateLoanCreate)?_c('RealEstateLoanCreate',{attrs:{"productId":_vm.categorizationUse.product.value.id,"isEditable":true,"smallDisplay":true},on:{"close":function($event){_vm.realEstateLoanCreate = false},"validate":function($event){_vm.realEstateLoanCreate = false}}}):_vm._e()],1),(
                !_vm.editingFields[index].supportingDocument &&
                line.supportingDocument
              )?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.editField(index, 'supportingDocument')}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-folder-outline ")]),(line.supportingDocument)?_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", line.supportingDocument ))+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.categorizationUse.getAttribute( "supportingDocument", line.supportingDocument ))+" ")])]),_c('v-icon',{staticClass:"edit-icon",attrs:{"size":"16"}},[_vm._v("mdi-pencil")])],1):(
                _vm.editingFields[index].supportingDocument ||
                _vm.categorizationUse.isRequired(
                  _vm.TypeReference.supportingDocument,
                  _vm.getCategory(line)
                ) ||
                _vm.categorizationUse.isOptional(
                  _vm.TypeReference.supportingDocument,
                  _vm.getCategory(line)
                )
              )?_c('v-autocomplete',{staticClass:"chip",attrs:{"items":_vm.supportingDocumentsByLine[index].value,"item-text":"name","item-value":"id","label":"Justificatif","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.saveAndClose(index, 'supportingDocument')}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('v-divider',{staticClass:"mb-2"}),_c('v-btn',{attrs:{"text":"","width":"100%","color":"primary"},on:{"click":function($event){_vm.supportingDocumentCreate = true}}},[_c('v-icon',{attrs:{"dense":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Ajouter un justificatif ")],1)]},proxy:true}],null,true),model:{value:(line.supportingDocument),callback:function ($$v) {_vm.$set(line, "supportingDocument", $$v)},expression:"line.supportingDocument"}}):_vm._e(),_c('DialogRight',{attrs:{"dialogModel":_vm.supportingDocumentCreate}},[(_vm.supportingDocumentCreate)?_c('SupportingDocumentCreate',{attrs:{"smallDisplay":true,"transactionState":_vm.transactionState},on:{"close":function($event){_vm.supportingDocumentCreate = false},"documentAdded":function (supportingDocumentId) { return (line.supportingDocument = supportingDocumentId); }}}):_vm._e()],1)],1)]),_c('span',[_c('v-text-field',{staticClass:"categorize-amount-input",attrs:{"value":line.amount.toFixed(2),"rules":[
              function () { return !!line.amount ||
                line.amount === 0 ||
                'Le champ ne peut pas être vide'; },
              function () { return _vm.countDecimals(line.amount) <= 2 ||
                'Le montant doit contenir au maximum deux décimales'; } ],"dense":"","hide-details":"auto","outlined":"","placeholder":"Montant","required":"","step":"0.01","suffix":"€","type":"number"},on:{"change":function (amount) { return _vm.categorizationUse.updateCategory(index, Object.assign({}, line,
                  {amount: Number(amount)})); }}})],1)])])}),(_vm.categorizationUse.getMissingAmount() !== 0)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Il reste "+_vm._s(_vm.$n(_vm.categorizationUse.getMissingAmount(), "currency", "fr-FR"))+" à affecter ")])],1):_vm._e(),(_vm.categorizationUse.isTvaCollectedIncorrect() && !_vm.hasCharges)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Le montant de la TVA n'est pas égal à "+_vm._s(_vm.rentalUnit.taxRateTVA)+"% du loyer encaissé. ")])],1):_vm._e(),(_vm.categorizationUse.isTvaCollectedIncorrect() && _vm.hasCharges)?_c('div',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"outlined":"","text":"","type":"info"}},[_vm._v(" Le montant de la TVA n'est pas égal à "+_vm._s(_vm.rentalUnit.taxRateTVA)+"% du loyer encaissé et des charges. ")])],1):_vm._e()],2),(_vm.accountingPeriodsStore.isLMNP)?_c('v-card-text',{staticClass:"card-subscription-free mb-5"},[_vm._v(" L'ajout de justificatifs est désormais facultatif, mais "),_c('b',[_vm._v("recommandé")]),_vm._v(" pour assurer la conformité avec les exigences du FEC et de la comptabilité d'engagement. ")]):_vm._e(),_c('Anomalies',{attrs:{"type":"transaction","checkNewAnomaliesType":_vm.CheckNewAnomaliesType.transaction,"transactions":[_vm.categorizationUse.getTransactionWithNewCategories()]}}),_c('div',{staticClass:"categorizationDuplication-btns mt-5"},[_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.categorizationUse.saveCategorization()}}},[_vm._v(" Valider ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }